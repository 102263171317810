<template>
    <div>
        <loadingicon v-if="loading" />
        <div class="checkout-main-div show">
            <div class="left-main ipad"><img :src="checkoutlogo" loading="lazy" alt="" class="logo">
                <div class="top-div updated">
                    <h1 class="checkout-header">Scientific Weight Loss. Everything Included.</h1>
                    <div class="div-block-1300">
                        <div class="div-block-1301">
                            <div class="checkout-bullet"><img :src="getIconPath('icon_meds')" loading="lazy" alt=""
                                    class="bullet-icon">
                                <div class="bullet-text">Monthly Weight loss medication</div>
                            </div>
                            <div class="checkout-bullet"><img :src="getIconPath('icon_support')" loading="lazy" alt=""
                                    class="bullet-icon">
                                <div class="bullet-text">On Demand Virtual Doctor Access</div>
                            </div>
                        </div>
                        <div class="div-block-1301">
                            <div class="checkout-bullet"><img :src="getIconPath('icon_best')" loading="lazy" alt=""
                                    class="bullet-icon">
                                <div class="bullet-text">6-Month Weight Loss Guarantee</div>
                            </div>
                            <div class="checkout-bullet remove"><img :src="getIconPath('icon_Check_1')" loading="lazy"
                                    alt="" class="bullet-icon">
                                <div class="bullet-text">Made in USA Compound GLP-1s</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="cntdiscnt" v-bind:class="{ 'hide-element': !checkDiscount }">
                    <div class="discntdiv">
                        <div class="text-block-382">Discount Applied:</div>
                        <div class="discountamount">
                            <div class="crncy">$</div>
                            <div class="amountdisc">{{ this.discount }}</div>
                            <div class="offtxt">OFF</div>
                        </div>
                    </div>
                    <div class="countdown-div">
                        <div class="text-block-382">This Offer Expires In:</div>
                        <div class="div-block-744 countdiv" id="hereitwill"></div>
                    </div>
                </div>
                <div class="program-div">
                    <h1 class="program-header">Choose Your Plan:</h1>
                    <div class="w-form">
                        <form id="wf-form-Plan-Selection" name="wf-form-Plan-Selection" data-name="Plan Selection"
                            method="get" data-wf-page-id="667b9ea7583d2f62be6bc3c6"
                            data-wf-element-id="59342add-44ef-cdc1-4a5a-2a89bcfaec17">
                            <div class="plan-choices-div">
                                <!--sema start-->
                                <div class="product-div" v-if="componentsToShow.includes('Com1')">
                                    <div data-w-id="7912f224-7e61-dc93-f141-2752aa1419bc"
                                        @click.prevent="selectMed('Semaglutide')" href="#"
                                        class="sema-link w w-inline-block not-selected"
                                        v-bind:class="{ 'selected': selected == true, 'not-selected': selected == false }">
                                        <div class="top-content-container">
                                            <div class="choice-bar-div sema"><img
                                                    src="@/assets/images/icon_banner1b-01.jpg" loading="lazy"
                                                    sizes="(max-width: 479px) 20vw, (max-width: 991px) 5vw, 6vw"
                                                    srcset="@/assets/images/icon_banner1b-01-p-500.jpg 500w, images/icon_banner1b-01.jpg 800w"
                                                    alt="" class="image-251 "></div>
                                            <div class="choice-info-div sema">
                                                <div class="image-info-div">
                                                    <div class="choice-text-div">
                                                        <div class="text-block-372">Most Popular</div>
                                                        <div class="compound sema "
                                                            v-bind:class="{ 'not-selected': selected == false }">
                                                            Semaglutide</div>
                                                        <div class="description sema "
                                                            v-bind:class="{ 'not-selected': selected == false }">same
                                                            active ingredient as<span class="text-span-35"
                                                                v-bind:class="{ 'not-selected': selected == false }"><br>Ozempic®
                                                                &amp; Wegovy®</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="div-block-1297">
                                                <div class="div-block-1298">
                                                    <div class="oldprice" v-if="checkDiscount"> $299 </div>
                                                    <div class="div-block-1302">
                                                        <div class="text-block-371 ">$</div>
                                                        <div>
                                                            <div class="text-block-370 ">{{ samemonthly - discount }}
                                                            </div>
                                                            <div class="text-block-373">A Month</div>
                                                        </div>
                                                    </div>
                                                    <div class="text-block-369" @click="makeSameOpen()">Tap to Learn
                                                        More ↓</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="sema-plan-options-div notselected"
                                            v-bind:class="{ 'open-oprions': semaOpen }">
                                            <div data-w-id="7912f224-7e61-dc93-f141-2752aa1419eb" class="close-details"
                                                @click.prevent="semaOpen = !semaOpen">
                                                <div class="text-block-357">CLOSE </div><img loading="lazy"
                                                    src="@/assets/images/icon_check-x.svg" alt="" class="image-248">
                                            </div>
                                            <div>
                                                <div class="text-block-364">Recommended Dosing Schedule &amp; Pricing
                                                </div>
                                            </div>
                                            <div class="tiers-main-div">
                                                <div class="tier-div">
                                                    <div class="text-block-365">Tier 1</div>
                                                    <div class="tier-box-div">
                                                        <div class="tier-bar">
                                                            <div class="text-block-366">$299 <span
                                                                    class="text-span-78">a month</span></div>
                                                        </div>
                                                        <div class="table-div">
                                                            <div class="month-div">
                                                                <div class="month-row">
                                                                    <div class="table-header">Month</div>
                                                                </div>
                                                                <div class="month-row sema"
                                                                    v-bind:class="{ 'highlight': (people.med == 'Semaglutide' && people.phase == 1) }">
                                                                    <div class="table-text">1</div>
                                                                </div>
                                                                <div class="month-row"
                                                                    v-bind:class="{ 'highlight': (people.med == 'Semaglutide' && people.phase == 2) }">
                                                                    <div class="table-text">2</div>
                                                                </div>
                                                                <div class="month-row last"
                                                                    v-bind:class="{ 'highlight': (people.med == 'Semaglutide' && people.phase == 3) }">
                                                                    <div class="table-text"> &nbsp;&nbsp;3+</div>
                                                                </div>
                                                            </div>
                                                            <div class="dose-div">
                                                                <div class="dose-row">
                                                                    <div class="table-header">Weekly Dosage</div>
                                                                </div>
                                                                <div class="dose-row sema"
                                                                    v-bind:class="{ 'highlight': (people.med == 'Semaglutide' && people.phase == 1) }">
                                                                    <div class="table-text">0.27 mg</div>
                                                                </div>
                                                                <div class="dose-row"
                                                                    v-bind:class="{ 'highlight': (people.med == 'Semaglutide' && people.phase == 2) }">
                                                                    <div class="table-text">0.54 mg</div>
                                                                </div>
                                                                <div class="dose-row last"
                                                                    v-bind:class="{ 'highlight': (people.med == 'Semaglutide' && people.phase == 3) }">
                                                                    <div class="table-text">*1.08 mg</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="tier-div">
                                                    <div class="text-block-365">Tier 2</div>
                                                    <div class="tier-box-div">
                                                        <div class="tier-bar">
                                                            <div class="text-block-366">$349 <span
                                                                    class="text-span-78">a month</span></div>
                                                        </div>
                                                        <div class="table-div">
                                                            <div class="month-div">
                                                                <div class="month-row">
                                                                    <div class="table-header">Month</div>
                                                                </div>
                                                                <div class="month-row"
                                                                    v-bind:class="{ 'highlight': (people.med == 'Semaglutide' && people.phase == 4) }">
                                                                    <div class="table-text">4+</div>
                                                                </div>
                                                                <div class="month-row"
                                                                    v-bind:class="{ 'highlight': (people.med == 'Semaglutide' && people.phase == 5) }">
                                                                    <div class="table-text">4+</div>
                                                                </div>
                                                                <div class="month-row last"
                                                                    v-bind:class="{ 'highlight': (people.med == 'Semaglutide' && people.phase == 6) }">
                                                                    <div class="table-text">4+</div>
                                                                </div>
                                                            </div>
                                                            <div class="dose-div">
                                                                <div class="dose-row">
                                                                    <div class="table-header">Weekly Dosage</div>
                                                                </div>
                                                                <div class="dose-row"
                                                                    v-bind:class="{ 'highlight': (people.med == 'Semaglutide' && people.phase == 4) }">
                                                                    <div class="table-text">0.54 mg</div>
                                                                </div>
                                                                <div class="dose-row"
                                                                    v-bind:class="{ 'highlight': (people.med == 'Semaglutide' && people.phase == 5) }">
                                                                    <div class="table-text">1.08 mg</div>
                                                                </div>
                                                                <div class="dose-row last"
                                                                    v-bind:class="{ 'highlight': (people.med == 'Semaglutide' && people.phase == 6) }">
                                                                    <div class="table-text">2.16 mg</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="text-block-368">*Average top dosage people stay on for
                                                maintenance</div>
                                            <ul role="list" class="list-2">
                                                <li class="list-item">
                                                    <div>Your dosing plan might vary based on the recommendations from a
                                                        doctor on the Yara Platform.</div>
                                                </li>
                                                <li class="list-item">
                                                    <div>Patients not currently on a GLP1 medication will initiate their
                                                        treatment on Tier 1. This is a standard procedure to ensure
                                                        safety and effectiveness.</div>
                                                </li>
                                                <li class="list-item">
                                                    <div>Total units in vials vary by pharmacy; you will always receive
                                                        enough for the given dosing schedule.</div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <!--sema end-->
                                <div class="product-div" v-if="componentsToShow.includes('Com2')">
                                    <div data-w-id="93193cea-2750-fa9b-219d-1e950290f562"
                                        @click.prevent="selectMed('Tirzepatide')" href="#" style="border-width: 3px;"
                                        class="tirz-link  w-inline-block" v-bind:class="{ 'selected': !selected }">
                                        <div class="top-content-container tirzep">
                                            <div class="choice-bar-div tirzep"><img
                                                    src="@/assets/images/icon_banner2c-01.png" loading="lazy"
                                                    sizes="(max-width: 479px) 100vw, (max-width: 991px) 5vw, 6vw"
                                                    srcset="@/assets/images/icon_banner2c-01-p-500.png 500w, @/assets/images/icon_banner2c-01.png 800w"
                                                    alt="" class="image-251"></div>
                                            <div class="choice-info-div tirzep">
                                                <div class="image-info-div">
                                                    <div class="choice-text-div tirzep">
                                                        <div class="text-block-372">Upgrade to</div>
                                                        <div class="compound tirzep"
                                                            v-bind:class="{ 'tirz_selected': !selected }">Tirzepatide
                                                        </div>
                                                        <div class="description tirzep"
                                                            v-bind:class="{ 'tirz_selected': !selected }">same active
                                                            ingredient as<span class="text-span-35"
                                                                v-bind:class="{ 'tirz_selected': !selected }"><br>Mounjaro®
                                                                &amp;
                                                                Zepbound®</span></div>
                                                    </div>
                                                    <img src="/img/Vial_small_Sema.9379390c.png" loading="lazy" alt=""
                                                        style="margin-top:25px;margin-left: 30px;" class="image-254">
                                                </div>
                                            </div>
                                            <div class="div-block-1297 tirzep"
                                                v-if="componentsToShow.includes('Com2') && componentsToShow.includes('Com1')">
                                                <div class="div-block-1299">
                                                    <div class="oldprice" v-if="checkDiscount"> $449 </div>
                                                    <div class="div-block-1302">
                                                        <div class="text-block-371">$</div>
                                                        <div>
                                                            <div class="text-block-370">{{ 449 - discount }}</div>
                                                            <div class="text-block-373">A Month</div>
                                                        </div>
                                                    </div>
                                                    <div class="text-block-369" @click="tirOpen = true;">Tap to Learn
                                                        More ↓</div>
                                                </div>
                                            </div>
                                            <div class="div-block-1297" v-else>
                                                <div class="div-block-1298">
                                                    <div class="div-block-1302">
                                                        <div class="text-block-371 ">$</div>
                                                        <div>
                                                            <div class="text-block-370 ">{{ monthly }}</div>
                                                            <div class="text-block-373">A Month</div>
                                                        </div>
                                                    </div>
                                                    <div class="text-block-369" @click="tirOpen = true;">Tap to Learn
                                                        More ↓</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="tirzep-plan-options notselected"
                                            v-bind:class="{ 'open-oprions': tirOpen }">

                                            <div data-w-id="93193cea-2750-fa9b-219d-1e950290f58f" class="close-tirzep"
                                                @click="tirOpen = !tirOpen">
                                                <div class="text-block-357">CLOSE</div><img loading="lazy"
                                                    src="@/assets/images/icon_check-x.svg" alt="" class="image-248">
                                            </div>
                                            <div>
                                                <div class="text-block-364">Recommended Dosing Schedule &amp; Pricing
                                                </div>
                                            </div>
                                            <div class="tiers-main-div">
                                                <div class="tier-div">
                                                    <div class="text-block-365">Tier 1</div>
                                                    <div class="tier-box-div">
                                                        <div class="tier-bar">
                                                            <div class="text-block-366">$449 <span
                                                                    class="text-span-78">a month</span></div>
                                                        </div>
                                                        <div class="table-div">
                                                            <div class="month-div">
                                                                <div class="month-row">
                                                                    <div class="table-header">Month</div>
                                                                </div>
                                                                <div class="month-row"
                                                                    v-bind:class="{ 'highlight': (people.med == 'Tirzepatide' && people.phase == 1) }">
                                                                    <div class="table-text">1</div>
                                                                </div>
                                                                <div class="month-row"
                                                                    v-bind:class="{ 'highlight': (people.med == 'Tirzepatide' && people.phase == 2) }">
                                                                    <div class="table-text">2</div>
                                                                </div>
                                                                <div class="month-row last"
                                                                    v-bind:class="{ 'highlight': (people.med == 'Tirzepatide' && people.phase == 3) }">
                                                                    <div class="table-text"> 3+</div>
                                                                </div>
                                                            </div>
                                                            <div class="dose-div">
                                                                <div class="dose-row">
                                                                    <div class="table-header">Weekly Dosage</div>
                                                                </div>
                                                                <div class="dose-row"
                                                                    v-bind:class="{ 'highlight': (people.med == 'Tirzepatide' && people.phase == 1) }">
                                                                    <div class="table-text">2.5 mg</div>
                                                                </div>
                                                                <div class="dose-row"
                                                                    v-bind:class="{ 'highlight': (people.med == 'Tirzepatide' && people.phase == 2) }">
                                                                    <div class="table-text">5 mg</div>
                                                                </div>
                                                                <div class="dose-row last"
                                                                    v-bind:class="{ 'highlight': (people.med == 'Tirzepatide' && people.phase == 3) }">
                                                                    <div class="table-text">*7.5 mg</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="tier-div">
                                                    <div class="text-block-365">Tier 2</div>
                                                    <div class="tier-box-div">
                                                        <div class="tier-bar">
                                                            <div class="text-block-366">$599 <span
                                                                    class="text-span-78">a month</span></div>
                                                        </div>
                                                        <div class="table-div">
                                                            <div class="month-div">
                                                                <div class="month-row">
                                                                    <div class="table-header">Month</div>
                                                                </div>
                                                                <div class="month-row"
                                                                    v-bind:class="{ 'highlight': (people.med == 'Tirzepatide' && people.phase == 4) }">
                                                                    <div class="table-text">4+</div>
                                                                </div>
                                                                <div class="month-row"
                                                                    v-bind:class="{ 'highlight': (people.med == 'Tirzepatide' && people.phase == 5) }">
                                                                    <div class="table-text">4+</div>
                                                                </div>
                                                                <div class="month-row last"
                                                                    v-bind:class="{ 'highlight': (people.med == 'Tirzepatide' && people.phase == 6) }">
                                                                    <div class="table-text">4+</div>
                                                                </div>
                                                            </div>
                                                            <div class="dose-div">
                                                                <div class="dose-row">
                                                                    <div class="table-header">Weekly Dosage</div>
                                                                </div>
                                                                <div class="dose-row"
                                                                    v-bind:class="{ 'highlight': (people.med == 'Tirzepatide' && people.phase == 4) }">
                                                                    <div class="table-text">10 mg</div>
                                                                </div>
                                                                <div class="dose-row"
                                                                    v-bind:class="{ 'highlight': (people.med == 'Tirzepatide' && people.phase == 5) }">
                                                                    <div class="table-text">12.5 mg</div>
                                                                </div>
                                                                <div class="dose-row last"
                                                                    v-bind:class="{ 'highlight': (people.med == 'Tirzepatide' && people.phase == 6) }">
                                                                    <div class="table-text">15 mg</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="text-block-368">*Average top dosage people stay on for
                                                maintenance</div>
                                            <ul role="list" class="list-2">
                                                <li class="list-item">
                                                    <div>Your dosing plan might vary based on the recommendations from a
                                                        doctor on the Yara Platform.</div>
                                                </li>
                                                <li class="list-item">
                                                    <div>Patients not currently on a GLP1 medication will initiate their
                                                        treatment on Tier 1. This is a standard procedure to ensure
                                                        safety and effectiveness.</div>
                                                </li>
                                                <li class="list-item">
                                                    <div>Total units in vials vary by pharmacy; you will always receive
                                                        enough for the given dosing schedule.</div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="product-div mobile" v-if="componentsToShow.includes('mob3')">
                                    <div data-w-id="ca0f3fe0-353d-af87-f661-f879d077416c"
                                        @click.prevent="selectMed('Tirzepatide')" class="tirz-link  w-inline-block"
                                        v-bind:class="{ 'selected': !selected }">
                                        <div class="top-content-container tirzep mobile">
                                            <div class="div-block-1303">
                                                <div class="div-block-1305"><img
                                                        src="@/assets/images/Vial_small_Tirzep.png" loading="lazy"
                                                        alt="" class="image-252">
                                                    <div class="div-block-1304">
                                                        <div class="div-block-1307">
                                                            <div class="text-block-374">Upgrade to Tirzepatide<br></div>
                                                            <div class="text-block-378">33% more weight loss vs
                                                                Semaglutide</div>
                                                        </div>
                                                        <div class="text-block-376" @click.prevent="tirOpen = true;"
                                                            @click="tirOpen = true;">Learn More</div>
                                                    </div>
                                                </div>
                                                <div class="div-block-1306">
                                                    <div v-if="selected">
                                                        <svg width="35px" height="35px" viewBox="0 0 24 24" fill="none"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                                            <g id="SVGRepo_tracerCarrier" stroke-linecap="round"
                                                                stroke-linejoin="round"></g>
                                                            <g id="SVGRepo_iconCarrier">
                                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                                    d="M1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12ZM12.5 5.5C13.0523 5.5 13.5 5.94772 13.5 6.5V10.5H17.5C18.0523 10.5 18.5 10.9477 18.5 11.5V12.5C18.5 13.0523 18.0523 13.5 17.5 13.5H13.5V17.5C13.5 18.0523 13.0523 18.5 12.5 18.5H11.5C10.9477 18.5 10.5 18.0523 10.5 17.5V13.5H6.5C5.94772 13.5 5.5 13.0523 5.5 12.5V11.5C5.5 10.9477 5.94772 10.5 6.5 10.5H10.5V6.5C10.5 5.94772 10.9477 5.5 11.5 5.5H12.5Z"
                                                                    fill="#000000"></path>
                                                            </g>
                                                        </svg>
                                                    </div>
                                                    <div v-else>
                                                        <svg fill="#26ced1" height="35px" width="35px" version="1.1"
                                                            id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                                                            xmlns:xlink="http://www.w3.org/1999/xlink"
                                                            viewBox="0 0 490.05 490.05" xml:space="preserve"
                                                            stroke="#26ced1">
                                                            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                                            <g id="SVGRepo_tracerCarrier" stroke-linecap="round"
                                                                stroke-linejoin="round"></g>
                                                            <g id="SVGRepo_iconCarrier">
                                                                <g>
                                                                    <g>
                                                                        <path
                                                                            d="M418.275,418.275c95.7-95.7,95.7-250.8,0-346.5s-250.8-95.7-346.5,0s-95.7,250.8,0,346.5S322.675,513.975,418.275,418.275 z M157.175,207.575l55.1,55.1l120.7-120.6l42.7,42.7l-120.6,120.6l-42.8,42.7l-42.7-42.7l-55.1-55.1L157.175,207.575z">
                                                                        </path>
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </svg>
                                                    </div>

                                                    <div class="text-block-377">$150/month</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="tirzep-plan-options notselected"
                                            v-bind:class="{ 'open-oprions': tirOpen }">
                                            <div data-w-id="ca0f3fe0-353d-af87-f661-f879d0774195" class="close-tirzep"
                                                @click.prevent="tirOpen = false;">
                                                <div class="text-block-357">CLOSE</div><img loading="lazy"
                                                    src="@/assets/images/icon_check-x.svg" alt="" class="image-248">
                                            </div>
                                            <div>
                                                <div class="text-block-364">Recommended Dosing Schedule &amp; Pricing
                                                </div>
                                            </div>
                                            <div class="tiers-main-div">
                                                <div class="tier-div">
                                                    <div class="text-block-365">Tier 1</div>
                                                    <div class="tier-box-div">
                                                        <div class="tier-bar">
                                                            <div class="text-block-366">$449 <span
                                                                    class="text-span-78">a month</span></div>
                                                        </div>
                                                        <div class="table-div">
                                                            <div class="month-div">
                                                                <div class="month-row">
                                                                    <div class="table-header">Month</div>
                                                                </div>
                                                                <div class="month-row"
                                                                    v-bind:class="{ 'highlight': (people.med == 'Tirzepatide' && people.phase == 1) }">
                                                                    <div class="table-text">1</div>
                                                                </div>
                                                                <div class="month-row"
                                                                    v-bind:class="{ 'highlight': (people.med == 'Tirzepatide' && people.phase == 2) }">
                                                                    <div class="table-text">2</div>
                                                                </div>
                                                                <div class="month-row last"
                                                                    v-bind:class="{ 'highlight': (people.med == 'Tirzepatide' && people.phase == 3) }">
                                                                    <div class="table-text"> 3+</div>
                                                                </div>
                                                            </div>
                                                            <div class="dose-div">
                                                                <div class="dose-row">
                                                                    <div class="table-header">Weekly Dosage</div>
                                                                </div>
                                                                <div class="dose-row"
                                                                    v-bind:class="{ 'highlight': (people.med == 'Tirzepatide' && people.phase == 1) }">
                                                                    <div class="table-text">2.5 mg</div>
                                                                </div>
                                                                <div class="dose-row"
                                                                    v-bind:class="{ 'highlight': (people.med == 'Tirzepatide' && people.phase == 2) }">
                                                                    <div class="table-text">5 mg</div>
                                                                </div>
                                                                <div class="dose-row last"
                                                                    v-bind:class="{ 'highlight': (people.med == 'Tirzepatide' && people.phase == 3) }">
                                                                    <div class="table-text">*7.5 mg</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="tier-div">
                                                    <div class="text-block-365">Tier 2</div>
                                                    <div class="tier-box-div">
                                                        <div class="tier-bar">
                                                            <div class="text-block-366">$599 <span
                                                                    class="text-span-78">a month</span></div>
                                                        </div>
                                                        <div class="table-div">
                                                            <div class="month-div">
                                                                <div class="month-row">
                                                                    <div class="table-header">Month</div>
                                                                </div>
                                                                <div class="month-row"
                                                                    v-bind:class="{ 'highlight': (people.med == 'Tirzepatide' && people.phase == 4) }">
                                                                    <div class="table-text">4+</div>
                                                                </div>
                                                                <div class="month-row"
                                                                    v-bind:class="{ 'highlight': (people.med == 'Tirzepatide' && people.phase == 5) }">
                                                                    <div class="table-text">4+</div>
                                                                </div>
                                                                <div class="month-row last"
                                                                    v-bind:class="{ 'highlight': (people.med == 'Tirzepatide' && people.phase == 6) }">
                                                                    <div class="table-text">4+</div>
                                                                </div>
                                                            </div>
                                                            <div class="dose-div">
                                                                <div class="dose-row">
                                                                    <div class="table-header">Weekly Dosage</div>
                                                                </div>
                                                                <div class="dose-row"
                                                                    v-bind:class="{ 'highlight': (people.med == 'Tirzepatide' && people.phase == 4) }">
                                                                    <div class="table-text">10 mg</div>
                                                                </div>
                                                                <div class="dose-row"
                                                                    v-bind:class="{ 'highlight': (people.med == 'Tirzepatide' && people.phase == 5) }">
                                                                    <div class="table-text">12.5 mg</div>
                                                                </div>
                                                                <div class="dose-row last"
                                                                    v-bind:class="{ 'highlight': (people.med == 'Tirzepatide' && people.phase == 6) }">
                                                                    <div class="table-text">15 mg</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="text-block-368">*Average top dosage people stay on for
                                                maintenance</div>
                                            <ul role="list" class="list-2">
                                                <li class="list-item">
                                                    <div>Your dosing plan might vary based on the recommendations from a
                                                        doctor on the Yara Platform.</div>
                                                </li>
                                                <li class="list-item">
                                                    <div>Patients not currently on a GLP1 medication will initiate their
                                                        treatment on Tier 1. This is a standard procedure to ensure
                                                        safety and effectiveness.</div>
                                                </li>
                                                <li class="list-item">
                                                    <div>Total units in vials vary by pharmacy; you will always receive
                                                        enough for the given dosing schedule.</div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <h1 class="program-header" v-if="showFrequency">Choose Frequency:</h1>
                            <div class="pay-plans-main-div" v-if="showFrequency">
                                <label class="w-checkbox payplan-checkboxfield" @click="setPricing(1)">
                                    <div class="w-checkbox-input w-checkbox-input--inputType-custom checkbox-3"
                                        v-bind:class="{ 'w--redirected-checked': (people.amount == monthly) }"></div>
                                    <span class="checkbox-label-10  w-form-label" for="checkbox-5"
                                        v-bind:class="{ 'checked': (people.amount == monthly) }">
                                        <span class="text-span-82">Monthly</span><br><br>‍<br>
                                        <span class="text-span-83"><sup>$</sup></span><span class="text-span-81">{{
                                            monthly - discount }}</span><br><br>
                                        <span class="text-span-84">${{ monthly - discount }}</span> A Month<br>
                                    </span>
                                </label>
                                <label class="w-checkbox payplan-checkboxfield" @click="setPricing(3)"
                                    v-if="people.med == 'Semaglutide'">
                                    <div class="w-checkbox-input w-checkbox-input--inputType-custom checkbox-3"
                                        v-bind:class="{ 'w--redirected-checked': (people.amount == semathree) }"></div>
                                    <span class="checkbox-label-10 w-form-label" for="checkbox-5"
                                        v-bind:class="{ 'checked': (people.amount == semathree) }">
                                        <span class="text-span-82">3 Months</span><br><br>‍<br><span
                                            class="text-span-83"><sup>$</sup></span>
                                        <span class="text-span-81">{{ semathree }}</span><br><br>
                                        <span class="text-span-84">${{ semathree / 3 }}</span> A Month<br>
                                    </span>
                                </label>
                                <label class="w-checkbox payplan-checkboxfield" @click="setPricing(3)"
                                    v-if="people.med == 'Tirzepatide' && people.phase == 1">
                                    <div class="w-checkbox-input w-checkbox-input--inputType-custom checkbox-3"
                                        v-bind:class="{ 'w--redirected-checked': (people.amount == semathree) }"></div>
                                    <span class="checkbox-label-10 w-form-label" for="checkbox-5"
                                        v-bind:class="{ 'checked': (people.amount == semathree) }">
                                        <span class="text-span-82">3 Months</span><br><br>‍<br><span
                                            class="text-span-83"><sup>$</sup></span>
                                        <span class="text-span-81">{{ semathree }}</span><br><br>
                                        <span class="text-span-84">${{ semathree / 3 }}</span> A Month<br>
                                    </span>
                                </label>
                                <label class="w-checkbox payplan-checkboxfield" @click="setPricing(6)"
                                    v-if="people.med == 'Semaglutide'">
                                    <div class="w-checkbox-input w-checkbox-input--inputType-custom checkbox-3"
                                        v-bind:class="{ 'w--redirected-checked': (people.amount == semasix) }"></div>
                                    <span class="checkbox-label-10 w-form-label" for="checkbox-5"
                                        v-bind:class="{ 'checked': (people.amount == semasix) }">
                                        <span class="text-span-82">6 Months</span><br><br>‍<br><span
                                            class="text-span-83"><sup>$</sup></span>
                                        <span class="text-span-81">{{ semasix }}</span><br><br>
                                        <span class="text-span-84">${{ semasix / 6 }}</span> A Month<br>
                                    </span>
                                </label>
                            </div>
                            <button type="submit" data-wait="Please wait..." @click.prevent="sendToStep2()"
                                v-if="people.pay_terms == 1" class="submit-button-9 w-button checkout-butt"><strong>${{
                                    people.amount - discount }}</strong> Proceed to Checkout -&gt;</button>
                            <button type="submit" data-wait="Please wait..." @click.prevent="sendToStep2()" v-else
                                class="submit-button-9 w-button checkout-butt"><strong>${{ people.amount }}</strong>
                                Proceed to Checkout -&gt;</button>
                            <div class="disclaimer-div">
                                <div class="text-block-361">*</div>
                                <div>
                                    <p class="paragraph-80">Up to 16.2% weight loss in a study by <a
                                            href="https://www.nature.com/articles/s41591-022-02026-4?utm_medium=affiliate&amp;utm_source=commission_junction&amp;utm_campaign=CONR_PF018_ECOM_GL_PBOK_ALWYS_DEEPLINK&amp;utm_content=textlink&amp;utm_term=PID100086038&amp;CJEVENT=3a92a053113d11ef826e02ed0a82b82d">Nature
                                            Medicine</a>. Up to 21% weight loss with diet plan in a study done by <a
                                            href="https://www.nejm.org/doi/10.1056/NEJMoa2206038">The New England
                                            Journal of medicine </a>
                                    </p>
                                </div>
                            </div>
                        </form>
                        <div class="w-form-done">
                            <div>Thank you! Your submission has been received!</div>
                        </div>
                        <div class="w-form-fail">
                            <div>Oops! Something went wrong while submitting the form.</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="right-main hideipad" v-bind:class="{ 'ifg_right': isIfg }">
                <div class="div-block-1293">
                    <a href="https://yarahealth.helpscoutdocs.com/" target="_blank"
                        class="link-block-20 w-inline-block">
                        <div class="text-block-351">Need Help</div><img src="@/assets/images/icon_faq.svg"
                            loading="lazy" alt="" class="image-246">
                    </a>
                </div>
                <p class="paragraph-65">&quot;I feel better, look better and finally found a
                    weight loss program that works..and I could stick to.&quot;</p>
                <div class="text-block-249">Michelle P.</div>
                <div class="text-block-260">BACKED BY RESEARCH FROM </div>
                <div class="div-block-1144"><img src="@/assets/images/SM_Web_vert_LG-blk.svg" loading="lazy" alt=""
                        class="image-203"><img src="@/assets/images/-medialogo_WebMD_blk.png" loading="lazy"
                        sizes="(max-width: 479px) 100vw, 160px"
                        srcset="@/assets/images/-medialogo_WebMD_blk-p-500.png 500w, @/assets/images/-medialogo_WebMD_blk-p-800.png 800w, @/assets/images/-medialogo_WebMD_blk.png 1000w"
                        alt="" class="image-203"></div>
                <div class="div-block-1144"><img src="@/assets/images/mayo-clinic-logo-w-copy_blk.png" loading="lazy"
                        sizes="(max-width: 479px) 100vw, (max-width: 767px) 34vw, 193.9765625px"
                        srcset="@/assets/images/mayo-clinic-logo-w-copy_blk-p-500.png 500w, @/assets/images/mayo-clinic-logo-w-copy_blk.png 548w"
                        alt="" class="image-203"><img src="@/assets/images/-medialogo_HarvardUniversity_blk.png"
                        loading="lazy" sizes="(max-width: 479px) 100vw, 145.453125px"
                        srcset="@/assets/images/-medialogo_HarvardUniversity_blk-p-500.png 500w, @/assets/images/-medialogo_HarvardUniversity_blk-p-800.png 800w, @/assets/images/-medialogo_HarvardUniversity_blk-p-1080.png 1080w, @/assets/images/-medialogo_HarvardUniversity_blk.png 1200w"
                        alt="" class="image-203"></div>
            </div>
        </div>

        <!--payment make-->

    </div>
</template>

<script>
export default {
    name: 'cardCheckout',
    props: ['baseUrl', 'mainlogo', 'checkoutlogo', 'iconSVGColor'],
    components: {},
    data() {
        return {
            el: 1,
            isIfg: false,
            samemonthly: '',
            selectedMed: '',
            isAbove480: window.innerWidth > 480,
            openMob: false,
            errorReason: false,
            interval: null,
            cardElement: null,
            isApplePaySupported: false,
            loading: false,
            session: '',
            discount: 0,
            timekey: 1,
            hide: 0,
            monthly: 299,
            semathree: 897,
            semasix: 1554,
            temp_discount: 0,
            coupon: '',
            valid_coupon: true,
            initiallyOn: '',
            tirOpen: false,
            semaOpen: false,
            addErr: false,
            mkey: 0,
            selected: "none",
            couponapplied: false,
            semaselected: true,
            people: {
                id: '',
                first_name: '',
                last_name: '',
                email: '',
                phone: '',
                phone_first: '',
                phone_second: '',
                phone_third: '',
                address_line_1: '',
                address_line_2: '',
                city: "",
                state: "",
                zip: '',
                cardToken: '',
                cardDetails: '',
                amount: 399,
                med: '',
                coupon_map_to: '',
                health_id: '',
                full_address: '',
                utm_campaign: '',
                phase: 1,
                pay_terms: 1,
            }
        }
    },
    computed: {
        showFrequency() {
            if (this.$route.query.frm_ref == "QualifyingGLP") {
                return false;
            }
            return true
        },
        componentsToShow() {
            const plan = this.$route.query.plan;
            const isMobile = window.innerWidth <= 768; // mobile breakpoint
            if (plan === 'none' || plan === 'Semaglutide' || plan == 'Wegovy' || plan == 'Ozempic' || plan === undefined) {
                return isMobile ? ['Com1', 'mob3'] : ['Com1', 'Com2'];
            } else {
                return ['Com2'];
            }
        },
        checkDiscount() {
            if (this.discount > 0) {
                return true;
            }
            return false;
        }
    },
    watch: {
        el() {
            this.scrolltoTop();
        }
    },
    mounted() {
        this.getPaitient();
        this.checkIFG();
        this.initializeCountdownTimer();
    },
    methods: {
        initializeCountdownTimer() {
            if (this.checkDiscount) {

                if (typeof window.CountdownTimer !== 'undefined') {
                    let offer = "discount100"
                    if (this.discount == 50) {
                        offer = "discount50"
                    }
                    const countdownTimer = new window.CountdownTimer({
                        selector: '#sytim-timer',
                        redirectUrl: '',
                        message: '',
                        offer: offer,
                        style: 'inline',
                        identifier: this.$route.query.id,
                        containerId: 'hereitwill',
                        fontColor: '#002646',
                        backgroundColor: 'transparent',
                        mediaGap: "0.2rem"
                    });
                    countdownTimer.startTimer();
                    this.myStyleAdder();
                } else {
                    console.error('CountdownTimer is not defined.');
                }
            }

        },
        myStyleAdder() {
            const style = document.createElement('style');
            style.textContent = `
                                .sytim-clock div {
                                    padding: 17px 20px 0 0px !important;
                                    }
                                #sytim-inline-bar {
                                    padding-left: 0px !important;
                                }
                                @media (max-width: 768px) {
                                  #sytim-timer {
                                    padding: 0px;
                                  }
                                  #sytim-timer div {
                                    padding: 0px 0.2rem !important;
                                }

                                #sytim-inline-bar {
                                    padding: 0px !important;
                                }
                                #sytim-inline-bar .sytim-content {
                                margin-top: 22px;
                                }
                        }
                        @media (max-width: 400px) {
                             .sytim-clock div {
                                font-size: 1.5rem !important;
                                }       
                            .sytim-clock div[data-sytim-value="days"]:after, .sytim-clock div[data-sytim-value="hours"]:after, .sytim-clock div[data-sytim-value="minutes"]:after, .sytim-clock div[data-sytim-value="seconds"]:after {
                                    font-size: .55rem !important;
                                }
                        }

                      `;
            document.head.appendChild(style);
        },
        checkIFG() {
            console.log(this.iconSVGColor)
            if (this.iconSVGColor == "_ifg") {
                this.isIfg = true;
            }
        },
        getIconPath(path) {
            const images = require.context('@/assets/images', false, /\.svg$/);
            return images(`./${path}${this.iconSVGColor}.svg`);
        },
        sendToStep2() {
            this.$router.push({
                name: 'CheckoutStep2',
                query: {
                    "health_id": this.$route.query.health_id,
                    "id": this.$route.query.id,
                    "plan": this.people.med,
                    "state": this.people.state,
                    "pay_terms": this.people.pay_terms,
                    "aegate": btoa(this.people.amount).replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, ''),
                }
            });
        },

        isIpad() {
            const ua = navigator.userAgent;
            const isIpadMini = /iPad/.test(ua) && (window.devicePixelRatio === 2 || window.devicePixelRatio === 3) && (window.innerWidth === 768 || window.innerHeight === 768);
            const isIpadAir = /iPad/.test(ua) && (window.devicePixelRatio === 2 || window.devicePixelRatio === 3) && (window.innerWidth === 810 || window.innerHeight === 810);
            return isIpadMini || isIpadAir;
        },
        setPricing(val) {
            if (val == 3) {
                this.people.amount = this.semathree;
                this.people.pay_terms = 3
            } else if (val == 6) {
                this.people.amount = this.semasix;
                this.people.pay_terms = 6
            } else {
                this.people.amount = this.monthly;
                this.people.pay_terms = 1
            }
            if (this.people.pay_terms == 1) {
                this.discount = this.temp_discount;
            } else {
                this.discount = 0;
            }
        },
        selectMed(val) {
            this.people.med = val;
            if (val == 'Semaglutide') {
                this.selected = true;
            } else {
                this.selected = false;
            }
            this.getAmountToPay();
        },
        makeSameOpen() {
            this.semaOpen = true;
        },
        
        getAmountToPay() {
            if (this.people.med == 'Semaglutide') {
                if (this.people.phase <= 3) {
                    this.monthly = 299;
                    this.samemonthly = 299;
                    this.semathree = 777;
                    this.semasix = 1434;
                } else {
                    this.monthly = 349;
                    this.samemonthly = 349;
                    this.semathree = 897;
                    this.semasix = 1554;
                }
            } else {
                if (this.people.phase <= 3) {
                    this.monthly = 449;
                    this.semathree = 1197;
                } else {
                    this.monthly = 599;
                }
            }
            this.discount = this.temp_discount;
            this.people.amount = this.monthly;
        },

        getPaitient() {
            if (this.$route.query.utm_campaign) {
                this.people.aff_id = this.$route.query.utm_campaign;
            }

            if (this.$route.query.health_id && this.$route.query.id) {
                this.people.id = this.$route.query.id;
                this.people.health_id = this.$route.query.health_id;
            } else {
                this.$router.push({
                    name: 'QualifyingQuestion'
                });
            }

            if (this.$route.query.discount == 50 || this.$route.query.discount == 100 || this.$route.query.discount == 110  || this.$route.query.discount == 200) {
                this.people.discount = this.$route.query.discount;
                this.discount = this.people.discount;
                this.temp_discount = this.people.discount;
            }

            if (this.$route.query.name) {
                this.people.first_name = this.$route.query.name;
            }

            if (this.$route.query.phase) {
                this.people.phase = this.$route.query.phase;
            }

            if (this.$route.query.plan) {
                if (this.$route.query.plan == 'Ozempic' || this.$route.query.plan == 'Wegovy' || this.$route.query.plan == 'Semaglutide') {
                    this.selectMed('Semaglutide');
                } else if (this.$route.query.plan == 'Tirzepatide' || this.$route.query.plan == 'Mounjaro' || this.$route.query.plan == 'Zepbound') {
                    this.selectMed('Tirzepatide');
                } else {
                    this.selectMed('Semaglutide');
                }
            } else {
                this.selectMed('Semaglutide');
            }

        },

        scrolltoTop() {
            setTimeout(function () {
                window.scroll({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                });
            }, 100);
        },
    },
    created(){
        this.scrolltoTop();
    }

}
</script>

<style scoped>
.hide-element {
    display: none !important;
}

.selected {
    background-color: #002646 !important;
    border-width: 3px !important;
    border-color: #26CED1 !important;
    border-radius: 8px !important;
    color: #FFFFFF !important;
}

@media screen and (max-width: 460px) {
    .countdiv {
        margin-left: 20px !important;
    }
}

.checkbox-label-10.checked,
.checkbox-label-10:active {
    color: #fff;
    border-color: #26CED1;
}

.checkbox-label-10 {
    color: #000;
    text-align: center;
    width: 100%;
    margin-bottom: 0;
    padding-top: 18px;
    padding-bottom: 18px;
    font-size: 11px;
    line-height: 11px;
    position: relative;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border: 3px solid;
    border-color: #002646;
    border-radius: 5px;
}

.checkbox-label-10:hover {
    color: #fff;
    background-color: #002646;
    border-radius: 5px;
    border-color: #26CED1;
}

.discountamount {
    background: #002646;
    padding: 10px 25px;
    border: 2px dashed #fff;
    color: #FFF;
    display: flex;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: flex-start;
}

.tirz_selected {
    color: #fff !important;
}

.cntdiscnt {
    display: flex;
    align-content: flex-start;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    gap: 10%;
    margin-bottom: 30px;
    margin-top: 30px;

}

.amountdisc {
    font-size: 45px;
    line-height: 45px;
    font-weight: bold;
    font-family: 'Lora';
}

.crncy {
    font-family: 'Lora';
    font-size: 25px;
    line-height: 35px;
    font-weight: bold;
}

.offtxt {
    font-family: 'Lora';
    font-size: 25px;
    line-height: 35px;
    font-weight: bold;
}

.discntdiv .text-block-382 {
    margin-bottom: 10px;
    font-weight: 600;
}

.cntdiscnt .countdown-div {
    width: auto;
    align-items: start;
    text-align: left;
    margin-bottom: 0px;
}

.cntdiscnt .sytim-clock div {
    padding: 0 0rem;
    padding-right: 1rem;
    font-size: 45px;
    line-height: 1;
}

.cntdiscnt #sytim-inline-bar {
    padding: 0px;
}

.oldprice {
    font-size: 20px;
    font-weight: 700;
    text-decoration: line-through;
    text-decoration-color: red;
    line-height: 1;
    margin-bottom: 10px;
}

.cntdiscnt .countdown-div .text-block-382 {
    font-weight: 600;
}

.cntdiscnt .sytim-content {
    padding: 18px 0 0;
}
.open-oprions{
    display: block !important;
    color: black;
}
@media screen and (max-width: 479px) {
    .pay-plans-main-div {
        justify-content: flex-start !important;
        align-items: center;
        margin-top: 0;
        margin-bottom: 5px;
        flex-wrap: wrap;
        gap: 10px !important;
    }

    .div-block-1297.tirzep {
        margin-top: 20px !important;
    }

    .offtxt,
    .crncy {
        font-size: 15px;
        line-height: 25px;

    }

    .cntdiscnt .sytim-clock div {
        font-size: 30px;
    }

    .amountdisc {
        font-size: 30px;
        line-height: 32px;
    }

    .sytim-clock {
        padding: 0px 0px;
    }

    .cntdiscnt .sytim-content {
        padding: 20px 0 0;
    }

    .image-254 {
        width: 40px;
    }

    .div-block-1302 {
        display: inherit !important;
    }

    .div-block-1297 {
        margin-top: -60px !important;
    }

}   
</style>
