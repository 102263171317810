<template>
    <div class="show">
        <model-window v-if="showModel" @close="showModel = false" />
        <loadingicon v-if="loading" />
        <div class="new-summary" v-if="!isAbove480">
            <div class="header-div"><img :src="checkoutlogo" loading="lazy" alt="" class="image-242"></div>
            <div class="top-div updated" v-if="showHeader">
                <h1 class="checkout-header">You qualify {{ people.first_name }}!</h1>
                <p class="checkstep2-subheader"> You are just 3 days away from starting your life changing weight loss
                    program.</p>
            </div>
            <div class="dropdown-sum">
                <div class="order-summary-div" style=" background-color: #ffffff !important;">
                    <div data-hover="false" data-delay="0" class="order-dropdown w-dropdown"
                        v-bind:class="{ 'zindexcls': openMob }">
                        <div class="text-block-434">Order Summary</div>
                        <div class="div-block-269 _1 new">
                            <div class="div-block-960 new">
                                <div class="div-block-976">
                                    <div class="div-block-974">
                                        <div class="checkout-product-div step-2"></div>
                                        <div class="div-block-975">
                                            <div class="text-block-161">1</div>
                                        </div>
                                    </div>
                                    <div>
                                        <div class="text-block-87 step-2">{{ people.med }} Program</div>

                                        <div class="text-block-162" v-if="people.pay_terms <= 1">Billed Monthly</div>
                                        <div class="text-block-162" v-else>For {{ people.pay_terms }} Months Supply
                                        </div>
                                    </div>
                                </div>
                                <div class="text-block-141 new">${{ people.amount - people.discount }}</div>
                                <div class="no-discount-price" v-if="people.discount > 0"><sup>$</sup>{{ people.amount
                                    }}</div>
                                <div class="text-block-347 step-2" v-if="people.discount > 0"><sup>$</sup>{{
                                    people.amount - people.discount }}</div>
                                <div class="text-block-347 step-2" v-else><sup>$</sup>{{ people.amount }}</div>
                            </div>
                        </div>

                        <div class="form-block-9 w-form" v-if="showCoupon">
                            <form id="email-form-4" name="email-form-4" @submit.prevent="checkCoupon()">
                                <div class="div-block-977 checkout">
                                    <input v-model="coupon" v-bind:class="{ 'invalid': !valid_coupon }"
                                        class="text-field-5 coupon new w-input" maxlength="256"
                                        placeholder="Coupon Code" type="text" id="field-3" required>
                                    <button class="button-15 new w-button">Apply</button>
                                    <br />
                                </div>
                                <div class="offbuton" v-if="discount > 0">
                                    <div class="discount">
                                        <div class="text-block-426"><sup>$</sup>{{ people.discount }} Off</div><a href="#"
                                            @click.prevent="resetDiscount()" class="remove-discount w-inline-block">
                                            <div class="text-block-427">x</div>
                                        </a>
                                    </div>
                                </div>

                            </form>
                            <div class="w-form-done">
                                <div>Thank you! Your submission has been received!</div>
                            </div>
                            <div class="w-form-fail" style="display: block;" v-if="!valid_coupon">
                                <div>Coupon Expired</div>
                            </div>
                        </div>
                        <div class="summary-deets-div">
                            <div class="summary-entry"><img src="@/assets/images/icon_doctor.svg" loading="lazy" alt=""
                                    class="image-278">
                                <div class="item-detail">
                                    <div class="text-block-435">Doctor Support &amp; Monitoring</div>
                                </div>
                                <div class="item-price">
                                    <div class="no-discount-price step-2"><sup>$</sup>{{ 99 * people.pay_terms }}</div>
                                    <div class="text-block-436">Free</div>
                                </div>
                            </div>
                            <div class="summary-entry"><img src="@/assets/images/icon_testing.svg" loading="lazy" alt=""
                                    class="image-278">
                                <div class="item-detail">
                                    <div class="text-block-435">Metabolic testing &amp; blood work.</div>
                                </div>
                                <div class="item-price">
                                    <div class="no-discount-price step-2"><sup>$</sup>{{ 75 * people.pay_terms }}</div>
                                    <div class="text-block-436">Free</div>
                                </div>
                            </div>
                            <div class="summary-entry"><img src="@/assets/images/icon_delivery.svg" loading="lazy"
                                    alt="" class="image-278">
                                <div class="item-detail">
                                    <div class="text-block-435">Express<br>Shipping</div>
                                </div>
                                <div class="item-price">
                                    <div class="no-discount-price step-2"><sup>$</sup>{{ 40 * people.pay_terms }}</div>
                                    <div class="text-block-436">Free</div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
            <div class="express-main-div" v-if="isApplePaySupported">
                <div class="express-box">
                    <div class="text-block-334">Express Checkout</div>
                    <a href="#" @click.prevent="handleApplePay" class="link-block-17 w-inline-block">
                        <img src="@/assets/images/Apple_Pay_logo-w.svg" loading="lazy" alt="" class="image-243"></a>
                </div>
            </div>
            <div class="div-block-1284" v-bind:class="{ 'remove-gap': !isApplePaySupported }">
                <div class="text-block-335" v-if="isApplePaySupported">OR CONTINUE BELOW TO PAY WITH CREDIT CARD
                </div>
                <div class="w-form">
                    <form id="email-form-2" class="step-2-left-payment-form new-checkout new-mobile _2"
                        @submit.prevent="getCardToken">
                        <label for="Address" class="field-label-4 new">Shipping Address</label>
                        <div class="addrsinpuwithapt">
                            <vue-google-autocomplete id="google-autocomplete" placeholder="Start typing address..."
                            v-on:placechanged="getAddressData" types="geocode" country="us"></vue-google-autocomplete>
                            <input type="text" id="apt" placeholder="APT#" v-model="people.address_line_2" class="pac-target-input aptinput" >
                        </div>
                        <div class="div-block-853 new">
                            <div class="text-block-80"><strong>Credit Card</strong></div><img
                                src="@/assets/images/visa.png" loading="lazy" width="35" alt="" class="image-7"><img
                                src="@/assets/images/mastercard.png" loading="lazy" width="35" alt=""
                                class="image-7"><img src="@/assets/images/AmEx.png" loading="lazy" width="35" alt=""
                                class="image-7"><img src="@/assets/images/discover.png" loading="lazy" width="35" alt=""
                                class="image-7">
                        </div>
                        <div>
                            <div id="card-element" class="myelement" ref="myElement"></div>
                        </div>
                        <div class="div-block-1156">
                            <label class="w-checkbox checkbox-field new">
                                <input type="checkbox" id="checkbox-3" name="checkbox-3" data-name="Checkbox 3"
                                    class="w-checkbox-input" checked required>
                                <span class="checkbox-label new w-form-label" for="checkbox-3">I have read and agree
                                    to the website <a href="https://joinyara.com/returns" target="_blank"
                                        class="link-22"><span class="text-span-12 new">Refund Policy</span></a><span
                                        class="text-span-12">
                                    </span>and <a href="https://joinyara.com/terms" target="_blank"
                                        class="link-21"><span class="text-span-12 new">Terms and
                                            Conditions</span></a></span></label><label
                                class="w-checkbox checkbox-field new"><input type="checkbox" checked id="checkbox-3"
                                    name="checkbox-3" data-name="Checkbox 3" class="w-checkbox-input" required><span
                                    class="checkbox-label new w-form-label" for="checkbox-3">I certify that I am at
                                    least 18 years of age</span></label><label
                                class="w-checkbox checkbox-field new"><input type="checkbox" checked id="checkbox-3"
                                    name="checkbox-3" data-name="Checkbox 3" class="w-checkbox-input" required><span
                                    class="checkbox-label new w-form-label" for="checkbox-3">I agree to the <a
                                        href="https://joinyara.com/terms" target="_blank" class="link-23"><span
                                            class="text-span-12 new">Terms of
                                            Use</span></a>, <a href="https://joinyara.com/privacy-policy-new"
                                        target="_blank" class="link-24"><span class="text-span-12 new">Privacy
                                            Policy</span></a>, <a href="https://joinyara.com/hippa-notice"
                                        class="link-25"><span class="text-span-12 new">HIPPA
                                            Notice</span></a> and <a href="https://joinyara.com/tele-health-consent"
                                        target="_blank" class="link-26"><span class="text-span-12 new">Telehealth
                                            Consent</span></a></span></label>
                        </div>
                        <input type="hidden" name="xxTrustedFormCertUrl" id="xxTrustedFormCertUrl_0" ref="trustedFormUrl" value="">
                        <input type="submit" data-wait="Please wait..." class="submit-button-3 new w-button"
                            value="Next Step -&gt;">
                        <div class="div-block-1154">
                            <div class="text-block-252">If you do not qualify after your teledoc appointment then
                                you will be given a full refund.</div>
                        </div>
                    </form>
                    <div class="w-form-done">
                        <div>Thank you! Your submission has been received!</div>
                    </div>
                    <div class="w-form-fail" style="display: block;" v-if="errorReason">
                        <div>{{errorMessage}}.</div>
                    </div>
                    <div class="w-form-fail" style="display: block;" v-if="addErr">
                        <div>Please enter your address</div>
                    </div>

                </div>
            </div>
            <div style="height:180px"></div>
        </div>
        <div class="div-block-263" v-else>
            <div class="div-block-265">
                <div class="div-block-266 new last"><img :src="checkoutlogo" loading="lazy" alt=""
                        class="image-31 summary">
                    <div class="step-4">
                        <div class="div-block-1291">
                            <h1 class="heading-8 _1 b checkout">Everything included. One low monthly price.</h1>
                            <div class="text-block-250 checkout">Start your weight loss journey this week without
                                leaving your home.</div>
                        </div>
                        <div class="w-form">
                            <form id="email-form-2" @submit.prevent="getCardToken"
                                class="step-2-left-payment-form new-checkout new-mobile checkout-desk">

                                <div class="div-block-1290" v-if="isApplePaySupported">
                                    <div class="text-block-349">EXPRESS CHECKOUT</div>
                                    <a href="#" @click.prevent="handleApplePay" class="link-block-13 w-inline-block">
                                        <div class="div-block-1155"><img src="@/assets/images/Apple_Pay_logo-w.svg"
                                                loading="lazy" alt="" class="image-201"></div>
                                    </a>
                                </div>
                                <div class="text-block-350" v-if="isApplePaySupported">OR CONTINUE BELOW TO PAY WITH
                                    CREDIT CARD</div>
                                <div class="div-block-1292"><label for="email-5" class="field-label-4">Shipping
                                        Address</label>
                                        <div class="addrsinpuwithapt">
                                            <vue-google-autocomplete id="google-autocomplete" placeholder="Start typing address..."
                                            v-on:placechanged="getAddressData" types="geocode" country="us"></vue-google-autocomplete>
                                            <input type="text" id="apt" placeholder="APT#" v-model="people.address_line_2" class="pac-target-input aptinput" >
                                        </div>
                                    <div class="div-block-853 new">
                                        <div class="text-block-80"><strong>Credit Card</strong></div><img
                                            src="@/assets/images/visa.png" loading="lazy" width="35" alt=""
                                            class="image-7"><img src="@/assets/images/mastercard.png" loading="lazy"
                                            width="35" alt="" class="image-7"><img src="@/assets/images/AmEx.png"
                                            loading="lazy" width="35" alt="" class="image-7"><img
                                            src="@/assets/images/discover.png" loading="lazy" width="35" alt=""
                                            class="image-7">
                                    </div>
                                    <div>
                                        <label for="email-3" class="field-label-4 new">Card Number</label>
                                        <div id="card-element" class="myelement" ref="myElement"></div>
                                    </div>
                                    <div class="div-block-1156"><label class="w-checkbox checkbox-field new"><input
                                                type="checkbox" id="checkbox-3" name="checkbox-3" data-name="Checkbox 3"
                                                class="w-checkbox-input" checked required><span
                                                class="checkbox-label new w-form-label" for="checkbox-3">I have read
                                                and agree to the website <a href="https://joinyara.com/returns"
                                                    target="_blank" class="link-22"><span
                                                        class="text-span-12 new">Refund
                                                        Policy</span></a><span class="text-span-12"> </span>and <a
                                                    href="https://joinyara.com/terms" target="_blank"
                                                    class="link-21"><span class="text-span-12 new">Terms and
                                                        Conditions</span></a></span></label><label
                                            class="w-checkbox checkbox-field new"><input type="checkbox" checked
                                                id="checkbox-3" name="checkbox-3" data-name="Checkbox 3"
                                                class="w-checkbox-input" required><span
                                                class="checkbox-label new w-form-label" for="checkbox-3">I certify
                                                that I am at least 18 years of age</span></label><label
                                            class="w-checkbox checkbox-field new"><input type="checkbox" checked
                                                id="checkbox-3" name="checkbox-3" data-name="Checkbox 3"
                                                class="w-checkbox-input" required><span
                                                class="checkbox-label new w-form-label" for="checkbox-3">I agree to
                                                the <a href="https://joinyara.com/terms" target="_blank"
                                                    class="link-23"><span class="text-span-12 new">Terms of
                                                        Use</span></a>, <a
                                                    href="https://joinyara.com/privacy-policy-new" target="_blank"
                                                    class="link-24"><span class="text-span-12 new">Privacy
                                                        Policy</span></a>, <a href="https://joinyara.com/hippa-notice"
                                                    target="_blank" class="link-25"><span class="text-span-12 new">HIPPA
                                                        Notice</span></a> and <a
                                                    href="https://joinyara.com/tele-health-consent" target="_blank"
                                                    class="link-26"><span class="text-span-12 new">Telehealth
                                                        Consent</span></a></span></label></div>
                                                        <input type="hidden" name="xxTrustedFormCertUrl" id="xxTrustedFormCertUrl_0" ref="trustedFormUrl" value="">
                          
                                                        <input type="submit"
                                        data-wait="Please wait..." class="submit-button-3 new w-button"
                                        value="Next Step -&gt;">
                                </div>
                                <div class="div-block-1154">
                                    <div class="text-block-252">If you do not qualify after your teledoc appointment
                                        then you will be given a full refund.</div>
                                </div>
                            </form>

                            <div class="w-form-done">
                                <div>Thank you! Your submission has been received!</div>
                            </div>
                            <div class="w-form-fail" style="display: block;" v-if="errorReason">
                                <div>{{errorMessage}}.</div>
                            </div>
                            <div class="w-form-fail" style="display: block;" v-if="addErr">
                                <div>Please enter your address</div>
                            </div>
                        </div>

                        <!--call center-->
                        <div class="w-form" v-if="showCallCenter">
                            <div class="div-block-1292">
                                <a href="#" @click="sendLinkSMS($event)" data-wait="Please wait..."
                                    class="submit-button-3 new w-button">Send Checkout Link </a>
                            </div>
                            <div style="height:30px;"></div>
                            <div class="div-block-1292">
                                <a href="#" @click="checkSubscriptionStatus($event)" data-wait="Please wait..."
                                    class="submit-button-3 new w-button">Check Status
                                </a>
                            </div>
                        </div>
                        <!--call center -->
                    </div>
                </div>

                <div class="div-block-1288 step-2">
                    <div class="div-block-1348">
                        <a href="https://yarahealth.helpscoutdocs.com/" target="_blank"
                            class="link-block-22 w-inline-block">
                            <div class="text-block-406">Need Help</div>
                            <div class="help-div">
                                <div class="text-block-415">?</div>
                            </div>
                        </a>
                    </div>
                    <div class="summary-desk step-2">
                        <div class="div-block-269 _1 new">
                            <div class="div-block-960 new">
                                <div class="div-block-976">
                                    <div class="div-block-974">
                                        <div class="checkout-product-div step-2"></div>
                                        <div class="div-block-975">
                                            <div class="text-block-161">1</div>
                                        </div>
                                    </div>
                                    <div>
                                        <div class="text-block-87 step-2">{{ people.med }} Program </div>
                                        <div class="text-block-162" v-if="people.pay_terms <= 1">Billed Monthly</div>
                                        <div class="text-block-162" v-else>For {{ people.pay_terms }} Months Supply
                                        </div>
                                    </div>
                                </div>
                                <div class="no-discount-price" v-if="people.discount > 0">
                                    <sup>$</sup>{{ people.amount }}
                                </div>
                                <div class="text-block-347 step-2"><sup>$</sup>{{ people.amount - people.discount }}
                                </div>
                            </div>
                        </div>
                        <div class="div-block-269 _1 coupon new"></div>
                        <div class="text-block-90">Monthly Access. <span class="text-span-13">Cancel anytime.
                            </span></div>
                    </div>
                    <div class="form-block-9 w-form" v-if="showCoupon">
                        <form id="email-form-4" name="email-form-4" @submit.prevent="checkCoupon()">
                            <div class="div-block-977 checkout">
                                <input v-model="coupon" v-bind:class="{ 'invalid': !valid_coupon }"
                                    class="text-field-5 coupon new w-input" maxlength="256" placeholder="Coupon Code"
                                    type="text" id="field-3" required>
                                <button class="button-15 new w-button">Apply</button>
                                <br />
                            </div>
                            <div class="offbuton" v-if="discount > 0">
                                <div class="discount">
                                    <div class="text-block-426"><sup>$</sup>{{ discount }} Off</div><a href="#"
                                        @click.prevent="resetDiscount()" class="remove-discount w-inline-block">
                                        <div class="text-block-427">x</div>
                                    </a>
                                </div>
                            </div>
                        </form>
                        <div class="w-form-done">
                            <div>Thank you! Your submission has been received!</div>
                        </div>
                        <div class="w-form-fail" style="display: block;" v-if="!valid_coupon">
                            <div>Coupon Expired</div>
                        </div>
                    </div>
                    <div class="step-2-summary">
                        <div class="text-block-434">Order Summary</div>
                        <div class="summary-deets-div">
                            <div class="summary-entry"><img src="@/assets/images/icon_vial.svg" loading="lazy" alt=""
                                    class="image-278">
                                <div class="item-detail">
                                    <div class="text-block-435"><span v-if="people.pay_terms <= 1">First Month Supply
                                        </span><span v-else>{{ people.pay_terms }} Months Supply </span> <span
                                            v-if="parseInt(people.discount) > 0">with <sup>$</sup>{{ people.discount }}
                                            discount</span>
                                    </div>
                                    <div class="text-block-437" v-if="people.pay_terms <= 1">Month 2 onwards
                                        ${{ people.amount }}</div>
                                </div>
                                <div class="item-price">
                                    <div class="no-discount-price step-2" v-if="parseInt(people.discount) > 0">
                                        <sup>$</sup>299
                                    </div>
                                    <div class="no-discount-price step-2" v-else-if="people.pay_terms > 1">
                                        <sup>$</sup>{{ getOriginalPrice }}
                                    </div>
                                    <div class="text-block-436"><sup>$</sup>{{ people.amount -
                                        parseInt(people.discount) }}
                                    </div>
                                </div>
                            </div>
                            <div class="summary-entry"><img src="@/assets/images/icon_doctor.svg" loading="lazy" alt=""
                                    class="image-278">
                                <div class="item-detail">
                                    <div class="text-block-435">Doctor Support and Monitoring</div>
                                </div>
                                <div class="item-price">
                                    <div class="no-discount-price step-2"><sup>$</sup>{{ 99 * people.pay_terms }}</div>
                                    <div class="text-block-436">Free</div>
                                </div>
                            </div>
                            <div class="summary-entry"><img src="@/assets/images/icon_testing.svg" loading="lazy" alt=""
                                    class="image-278">
                                <div class="item-detail">
                                    <div class="text-block-435">Metabolic testing and blood work included</div>
                                </div>
                                <div class="item-price">
                                    <div class="no-discount-price step-2"><sup>$</sup>{{ 75 * people.pay_terms }}</div>
                                    <div class="text-block-436">Free</div>
                                </div>
                            </div>
                            <div class="summary-entry"><img src="@/assets/images/icon_delivery.svg" loading="lazy"
                                    alt="" class="image-278">
                                <div class="item-detail">
                                    <div class="text-block-435">Overnight Shipping</div>
                                </div>
                                <div class="item-price">
                                    <div class="no-discount-price step-2"><sup>$</sup>{{ 40 * people.pay_terms }}</div>
                                    <div class="text-block-436">Free</div>
                                </div>
                            </div>
                            <div class="savings-div"><img src="@/assets/images/party-popper.png" loading="lazy" alt=""
                                    class="image-279">
                                <div class="text-block-438" v-if="people.pay_terms <= 1">You saved <sup>$</sup>{{ 214 +
                                    parseInt(people.discount) }} on your order!</div>
                                <div class="text-block-438" v-else>You saved <sup>$</sup>{{ (214 * people.pay_terms) +
                                    (getOriginalPrice - people.amount) }} on your order!</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {
    BasisTheory
} from "@basis-theory/basis-theory-js";
const bt = await new BasisTheory().init("key_prod_us_pub_9drv27VKwcUVoP5BNnnyKZ", {
    elements: true
});
import VueGoogleAutocomplete from 'vue-google-autocomplete';
import loading from '../../common/loadingScreen.vue';
import modelWindow from '../../common/modelWindow.vue'
export default {
    name: 'cardCheckout',
    props: ['baseUrl', 'mainlogo', 'checkoutlogo', 'iconSVGColor'],
    components: {
        'loadingicon': loading,
        VueGoogleAutocomplete,
        'model-window': modelWindow
    },
    data() {
        return {
            showModel: false,
            el: 1,
            selectedMed: '',
            isIfg: false,
            isAbove480: window.innerWidth > 480,
            openMob: false,
            errorReason: false,
            interval: null,
            cardElement: null,
            isApplePaySupported: false,
            loading: false,
            session: '',
            discount: 0,
            hide: 0,
            monthly: 299,
            semathree: 897,
            semasix: 1554,
            coupon: '',
            valid_coupon: true,
            initiallyOn: '',
            tirOpen: false,
            semaOpen: false,
            addErr: false,
            mkey: 0,
            selected: "none",
            currentUrl:'',
            couponapplied: false,
            semaselected: true,
            showCoupon: true,
            showCall: false,
            errorMessage:'Invalid card details',
            people: {
                id: '',
                first_name: '',
                last_name: '',
                email: '',
                phone: '',
                phone_first: '',
                phone_second: '',
                phone_third: '',
                address_line_1: '',
                address_line_2: '',
                city: "",
                state: "",
                zip: '',
                cardToken: '',
                cardDetails: '',
                amount: 0,
                discount: 0,
                med: '',
                coupon_map_to: '',
                health_id: '',
                full_address: '',
                utm_campaign: '',
                phase: 1,
                pay_terms: 1,
                callcenter_source: '',
                forward_by_call: ''
            }
        }
    },
    computed: {
        getOriginalPrice() {
            if (this.people.med == "Semaglutide") {
                return 299 * this.people.pay_terms;
            } else {
                return 449 * this.people.pay_terms;
            }
        },
        showHeader() {
            if (this.$route.query.showheader) {
                return true;
            }
            return false;
        },
        showCallCenter() {
            const callcenter_source = this.$route.query.callcenter_source;
            if (callcenter_source) {
                return true;
            } else {
                return false;
            }
        },
        componentsToShow() {
            const plan = this.$route.query.plan;
            const isMobile = window.innerWidth <= 768; // Define your mobile breakpoint

            if (plan === 'none' || plan === 'Semaglutide' || plan == 'Wegovy' || plan == 'Ozempic') {
                return isMobile ? ['Com1', 'mob3'] : ['Com1', 'Com2'];
            } else {
                return ['Com2'];
            }
        }
    },
    watch: {
        el() {
            this.scrolltoTop();
        }
    },
    mounted() {
        this.createCardElement();
        this.getPaitient();
        this.checkApplePaySupport();
        this.checkIFG();
        this.currentUrl =  window.location.origin + window.location.pathname;
    },
    methods: {
        checkIFG() {
            if (this.iconSVGColor == "_ifg") {
                this.isIfg = true;
            }
        },
        resetDiscount() {
            this.discount = 0;
            this.people.discount = 0;
            this.couponapplied = false;
        },
        checkSubscriptionStatus(event) {
            event.preventDefault();
            window.axios.get(`${this.baseUrl}/check-subs/${this.people.id}`).then((response) => {
                console.log(response);
                if (response.data.data == "success") {
                    alert("User has completed checkout.")
                } else {
                    alert("Waitiing on user to complete checkout.")
                }
            }).catch((error) => {
                console.log(error);
            });

        },
        sendLinkSMS(event) {
            event.preventDefault();
            const currentUrl = new URL(window.location.href);
            const paramToRemove = 'callcenter_source';
            const paramToAdd = 'forward_by_call';
            const paramValue = currentUrl.searchParams.get(paramToRemove);
            currentUrl.searchParams.delete(paramToRemove);
            if (paramValue !== null) {
                currentUrl.searchParams.set(paramToAdd, paramValue);
            }
            const updatedUrl = currentUrl.toString();
            let message = "Hi it's Yara! Please click this custom link to enter your card information and finish sign up : " + updatedUrl;
            window.axios.post(`${this.baseUrl}/send-sms-callcenter`, {
                id: this.people.id,
                sms_type: "call",
                message: message
            }).then((response) => {
                console.log(response);
                alert("SMS sent")
            }).catch((error) => {
                console.log(error);
            });
        },
        checkCoupon() {
            if (this.couponapplied) {
                return false;
            }
            this.valid_coupon = true;
            window.axios.post(`${this.baseUrl}/validate-coupon`, {
                coupon: this.coupon
            }).then((response) => {
                if (response.data.status == 'success') {
                    this.valid_coupon = true;
                    this.couponapplied = true;
                    if (response.data.c_type == 1) {
                        this.discount = response.data.c_val;
                        this.people.discount = this.discount;
                    } else {
                        this.discount = Math.round((this.people.amount * response.data.c_val) / 100);
                    }
                } else {
                    this.valid_coupon = false;
                }
                this.mkey++;
            }).catch((error) => {
                console.log(error);
                this.loading = false;
            });
        },
        isIpad() {
            const ua = navigator.userAgent;
            const isIpadMini = /iPad/.test(ua) && (window.devicePixelRatio === 2 || window.devicePixelRatio === 3) && (window.innerWidth === 768 || window.innerHeight === 768);
            const isIpadAir = /iPad/.test(ua) && (window.devicePixelRatio === 2 || window.devicePixelRatio === 3) && (window.innerWidth === 810 || window.innerHeight === 810);
            return isIpadMini || isIpadAir;
        },
        setPricing(val) {
            if (val == 3) {
                this.people.amount = this.semathree;
                this.people.pay_terms = 3
            } else if (val == 6) {
                this.people.amount = this.semasix;
                this.people.pay_terms = 6
            } else {
                this.people.amount = this.monthly;
                this.people.pay_terms = 1
            }
        },
        selectMed(val) {
            this.people.med = val;
            if (val == 'Semaglutide') {
                this.selected = true;
            } else {
                this.selected = false;
            }
            this.getAmountToPay();
        },
        makeSameOpen() {
            this.semaOpen = true;
        },
        checkApplePaySupport() {
            this.isApplePaySupported = false;
        },
        getPaitient() {
            const q = this.$route.query;

            if (!q.health_id || !q.id) {
                this.$router.push({ name: 'QualifyingQuestion' });
                return;
            }

            // Determine the plan name
            let planName = 'Semaglutide';
            if (q.plan === 'Semagludite' || q.plan === 'Tirzepatide') {
                planName = q.plan;
            }

            Object.assign(this.people, {
                first_name: q.name,
                id: q.id,
                health_id: q.health_id,
                phase: q.phase,
                med: planName,
                pay_terms: q.pay_terms || this.people.pay_terms,
                state: q.state,
                aff_id: q.utm_campaign,
                forward_by_call: q.forward_by_call,
                discount: [50, 100, 110, 200].includes(Number(q.discount)) ? q.discount : this.people.discount,
                amount: q.aegate ? atob(q.aegate.replace(/-/g, '+').replace(/_/g, '/')) : planName === 'Tirzepatide' ? 449 : 299,
                callcenter_source: q.callcenter_source || this.people.callcenter_source
            });

            this.discount = this.people.discount;
            this.showCoupon = !([50, 100, 110].includes(Number(q.discount)) || (q.pay_terms && q.pay_terms > 1));
            this.showCall = !!q.callcenter_source;

            if (q.coupon && this.people.pay_terms == 1) {
                this.coupon = q.coupon;
                this.checkCoupon();
            }
        },
        handleApplePay() {
        },
        updateAddress() {
            if (localStorage.getItem('formResponse')) {
                let formResponse = JSON.parse(localStorage.getItem('formResponse'));
                formResponse["med"] = this.people.med;
                formResponse["id"] = this.people.id;
                formResponse["health_id"] = this.people.health_id;
                formResponse["shipping"] = {
                    address_line_1: this.people.address_line_1,
                    address_line_2: this.people.address_line_2,
                    city: this.people.city,
                    state: this.people.state,
                    zip: this.people.zip
                }
                localStorage.setItem('formResponse', JSON.stringify(formResponse));
            }
            window.axios.post(`${this.baseUrl}/save-address`, this.people);
        },
        getCardToken() {
            this.addErr = false;
            this.loading = true;
            if (this.people.zip == '' || this.people.zip == null) {
                this.addErr = true;
                this.loading = false;
                return false;
            }
            this.errorReason = false;
            bt.tokens
                .create({
                    type: "card",
                    data: this.cardElement,
                })
                .then((token) => {
                    this.people.cardToken = token.id;
                    this.people.cardDetails = token.data;
                    this.submitForm();
                })
                .catch((error) => {
                    console.log(error);
                    this.errorReason = true;
                    this.loading = false;
                    this.errorMessage = "Invalid card details"
                });
        },
        pushToRoute() {
            try{
                const urlvalue = this.$refs.trustedFormUrl.value;
                window.axios.post(`${this.baseUrl}/trust-form`, {
                    "user_id" : this.$route.query.id,
                    "url" : urlvalue,
                });
            }catch(error){
                console.log(error)
            }
            this.$router.push({
                name: 'AfterCheckout',
                query: {
                    "health_id": this.$route.query.health_id,
                    "id": this.$route.query.id,
                    "plan": this.people.med,
                    "state": this.people.state,
                }
            });
            this.getAffiliateAndSave(this.currentUrl);
        },
        submitForm() {
            this.loading = true;
            this.people.program = this.people.med;
            window.axios.post(`${this.baseUrl}/create-payment-profile`, this.people).then((response) => {
                if (response.data.status == 'success' && response.data.pay_resp == "APPROVED") {
                    localStorage.setItem("checkoutForm", JSON.stringify(this.people));
                    this.pushToRoute();
                    this.loading = false;
                } else {
                    this.errorReason = true;
                    this.errorMessage = response.data.pay_resp;
                    this.loading = false;
                }
            }).catch((error) => {
                console.log(error);
                this.loading = false;
            });
        },
        getAddressData(place) {
            // Get selected address details

            if (["MS", "LA"].includes(place.administrative_area_level_1)) {
                this.showModel = true;
            } else {
                this.people.address_line_1 += `${place.street_number} ${place.route}`;
                this.people.city = place.locality;
                this.people.state = place.administrative_area_level_1;
                this.people.zip = place.postal_code;
                if (this.people.city == undefined) {
                    this.people.city = place.administrative_area_level_2;
                }
                localStorage.setItem("userState", JSON.stringify(this.people.state));
                this.updateAddress();
            }
        },
        focusNextInput(nextIndex) {
            const inputs = document.querySelectorAll('.ph_one');
            console.log(inputs);
            if (nextIndex < inputs.length) {
                inputs[nextIndex].focus();
            }
        },
        focusNext(event) {
            const currentInput = event.target;
            const nextInput = currentInput.nextElementSibling;
            const prevInput = currentInput.previousElementSibling;
            console.log(nextInput);
            if (event.key === "Backspace" && currentInput.value === "") {
                if (prevInput) {
                    prevInput.focus();
                }
            } else if (currentInput.value.length === currentInput.maxLength && nextInput) {
                nextInput.focus();
            }
        },
        createCardElement() {
            this.cardElement = bt.createElement('card');
            this.cardElement.mount('#card-element');
        },
        getQueryParamOrCookie(url, paramName) {
            const urlObj = new URL(url);
            const paramValue = new URLSearchParams(urlObj.search).get(paramName);
            if (paramValue !== null) return paramValue;

            const cookieValue = document.cookie
                .split('; ')
                .find(row => row.startsWith(paramName + '='));
            return cookieValue ? cookieValue.split('=')[1] : null;
        },
        getAffiliateAndSave(currentUrl) {
            try {
                const url = window.location.href;
                const affiliateIdKey = 'utm_campaign';
                const tidKey = 'tid';
                const utm_sourceKey = 'utm_source';
                const affiliateId = this.getQueryParamOrCookie(url, affiliateIdKey);
                const tid = this.getQueryParamOrCookie(url, tidKey);
                const utm_source = this.getQueryParamOrCookie(url, utm_sourceKey);
                var parameterWithValue = null;
                var userAgent = "";

                if (this.people.forward_by_call) {
                    parameterWithValue = this.people.forward_by_call;
                } else if (this.people.callcenter_source) {
                    parameterWithValue = this.people.callcenter_source;
                }

                try {
                    userAgent = navigator.userAgent
                } catch (error) {
                    console.log(error)
                }
                window.axios.post(`${this.baseUrl}/register-affiliate`, {
                    "id": this.people.id,
                    "affiliate_id": affiliateId,
                    "utm_source": utm_source,
                    "tid": tid,
                    "discount": this.discount,
                    "med": this.people.med,
                    "state": this.people.state,
                    "amount": this.people.amount,
                    "pay_terms": this.people.pay_terms,
                    "userAgent": userAgent,
                    "ip_address": JSON.parse(localStorage.getItem("ip_address")),
                    "callcenter_source": parameterWithValue,
                    "forward_by_call": parameterWithValue,
                    "source_url" : currentUrl
                });
            } catch (error) {
                console.log(error)
            }

        },
        scrolltoTop() {
            setTimeout(function () {
                window.scroll({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                });
            }, 100);
        },
    },
    created() {
        this.scrolltoTop();
    }

}
</script>

<style scoped>
.checkstep2-subheader {
    font-family: var(--font-family);
    font-size: 20px;
}

@media screen and (max-width: 479px) {
    .pac-target-input {
        border: 1px solid !important;
        border-color: var(--field-box-outline) !important;
        height: 45px !important;
        font-size: 16px !important;
    }
}

.ifg_right {
    background: rgba(238, 251, 251, .9) !important;
}

.selected {
    background-color: #002646 !important;
    border-width: 3px !important;
    border-color: #26ced1 !important;
    border-radius: 8px !important;
    color: #FFFFFF !important;
}

.myelement {
    border: 1px solid #002646;
    border-radius: 6px;
    padding: 5px 0 5px 15px;
    display: block;
    width: 100%;
    margin-bottom: 10px;
    font-size: 12px;
    line-height: 1.42857143;
    color: #333333;
    background-color: #ffffff;
}

.open-oprions {
    display: block !important;
}

.notselected {
    color: #000 !important;
}

@media only screen and (max-width: 468px) {
    .showdesk {
        display: none;
    }

    .top-content-container.tirzep {
        background-image: none !important;
        background-size: 17%;
        padding-bottom: 0;
    }
}

@media only screen and (min-width: 768px) {
    .showmobile {
        display: none;
    }
}

.pac-target-input {
    height: 50px;
    border-width: 2px;
    border-color: #002646;
    border-radius: 3px;
    width: 100%;
    padding: 8px 12px;
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #333333;
    vertical-align: middle;
    background-color: #ffffff;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .hideipad {
        display: none;
    }

    .ipad {
        width: 100% !important;
    }
}

.indexcls {
    z-index: 901;
}

.mkeup {
    -webkit-transition: all .2s ease;
    transition: all .2s ease;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}

.show {
    display: block;
}

.hide {
    display: none !important;
    animation: slideOutToBottom 0.5s ease forwards;
}

.invalid {
    color: red !important;
    border: 2px solid red !important;
}

@media screen and (max-width: 479px) {
    .remove-gap {
        flex-flow: column;
        align-items: center;
        margin-bottom: 30px;
        padding-left: 3%;
        padding-right: 3%;
        display: flex;
        margin-top: -30px !important;
    }
}

.pac-target-input {
    border: 1px solid !important;
    border-color: var(--field-box-outline) !important;
}

.pac-target-input:focus {
    border-color: #4CAF50 !important;
    /* Change the border color on focus */
}

.offbuton {
    margin-bottom: 10px;
    display: flex;
    padding-left: 2%;
    padding-right: 2%;
}

.div-block-977.checkout {
    border-bottom: 0px solid #dfdfdf;
    margin-bottom: 0px;
}

@media screen and (max-width: 479px) {
    .offbuton {
        padding-left: 0%;
        padding-right: 0%;
    }

    .new-summary {
        padding-left: 3%;
        padding-right: 3%;
    }

    .order-summary-div {
        border: none !important;
    }

    .header-div {
        margin-bottom: 30px !important;
    }

    .div-block-1284 .w-form {
        width: 100%;
    }

    .express-main-div {
        margin-top: 0px;
        margin-bottom: 60px;
    }

    .field-label-4.new {
        color: var(--subheader-color);
        font-size: 18px !important;
        font-weight: bold;
        margin-bottom: 10px;
    }

    .div-block-977.checkout {
        padding-left: 0%;
        padding-right: 0%;
        margin-bottom: 0px;
        border: none;
    }

    .div-block-977.checkout .button-15.new {
        width: 30%;
        margin-left: 2px;
    }

    .div-block-269._1.new {
        padding-left: 0%;
        padding-right: 0%;
    }
}

.aptinput {
    max-width: 20%;
    margin-left: 5px;
}
.addrsinpuwithapt {
    display: flex;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    justify-content: center;
}
</style>
