<template>
<div style="background-color: #fff;">
    <div v-if="!loading" :key="winkey">
        <update-card :baseUrl="baseUrl" @CloseBox="updateCard = false;" @ShowToast="ShowToast" v-if="updateCard" />
        <confirm-cancel :baseUrl="baseUrl" @CloseBox="cancelSubs = false;" @ShowToastForCancel="ShowToastForCancel" @ShowToastForCancelError="ShowToastForCancelError" v-if="cancelSubs" />
        <toast-window :message="toastMessage" v-if="showT" />
        <section>
            <div data-animation="default" data-collapse="medium" data-duration="400" data-easing="ease" data-easing2="ease" role="banner" class="navbar-4 w-nav">
                <div class="div-block-1108">
                    <div class="div-block-1109">
                        <a href="#" class="brand-5 w-nav-brand"><img src="@/assets/images/yara-placeholder.svg" loading="lazy" alt="" class="image-187"></a>
                        <nav role="navigation" class="nav-menu-4 w-nav-menu">
                            <a href="#" @click="Logout($event)" aria-current="page" class="nav-link w-nav-link w--current">Logout</a>
                            <a href="https://www.facebook.com/groups/1591921128328697" target="_blank" class="nav-link w-nav-link">Community</a>
                            <a href="https://yarahealth.helpscoutdocs.com/" target="_blank" class="nav-link b w-nav-link">Help Portal</a>
                        </nav>
                        <div class="menu-button-2 w-nav-button" @click="OpenMenu()">
                            <div class="icon-17 w-icon-nav-menu"></div>
                        </div>
                    </div>
                    <div class="div-block-1110"></div>
                </div>
            </div>
            <div data-animation="default" data-collapse="medium" data-duration="400" data-easing="ease" data-easing2="ease" role="banner" class="navbar-4 w-nav">
                <div class="w-nav-overlay" v-bind:class="{ 'displaymenu': isopen }" data-wf-ignore="" id="w-nav-overlay-0">
                    <nav role="navigation" class="nav-menu-4 w-nav-menu" v-bind:class="{ 'trans': isopen }" data-nav-menu-open="">
                        <a href="#" aria-current="page" @click="Logout($event)" class="nav-link w-nav-link w--current w--nav-link-open">Logout</a>
                        <a href="#" @click="sendToPage($event, 'https://www.facebook.com/groups/1591921128328697')" target="_blank" class="nav-link w-nav-link w--nav-link-open">Community</a>
                        <a href="#" @click="sendToPage($event, 'https://yarahealth.helpscoutdocs.com/')" class="nav-link b w-nav-link w--nav-link-open">Help Portal</a>
                    </nav>
                </div>
            </div>
        </section>
        <section>
            <div class="div-block-1111">
                <div class="div-block-1112">
                    <div class="div-block-1113">
                        <div class="text-block-223"><strong class="bold-text-20">Hello {{ user.first_name }}!</strong>
                        </div>
                        <div class="text-block-224">Keep up to date with our easy to use portal.</div>
                        <div class="div-block-1114" v-if="user.onboard == 1">
                            <img src="@/assets/images/icon_Check_1.svg" loading="lazy" alt="" class="image-188">
                            <div class="text-block-225">Onboarding Questionnaire</div>
                        </div>
                        <div class="div-block-1114" v-else-if="user.onboard == 0 && transactions.length > 0">
                            <img src="@/assets/images/icon_Urgent.svg" loading="lazy" alt="" class="image-188">
                            <div>
                                <div class="text-block-225 no">Please complete onboarding.</div>
                                <div class="text-block-226">This needs to be completed in order to schedule an
                                    appointment.</div>
                                <div>
                                    <router-link :to="'/get-to-know?health_id=' + user.health_id + '&id=' + user.id + '&plan=' + subscription.plan" class="link-15">Click Here To Complete Onboarding &gt;&gt;</router-link>
                                </div>
                            </div>
                        </div>
                        <div class="div-block-1114" v-else>
                            <img src="@/assets/images/icon_Urgent.svg" loading="lazy" alt="" class="image-188">
                            <div>
                                <div class="text-block-225 no">Please complete onboarding.</div>
                                <div class="text-block-226">This needs to be completed in order to schedule an
                                    appointment.</div>
                                <div>
                                    <router-link :to="'/checkout?health_id=' + user.health_id + '&id=' + user.id" class="link-15">Click Here To Complete Onboarding &gt;&gt;</router-link>
                                </div>
                            </div>
                        </div>
                        <div class="div-block-1114" v-if="inFuture">
                            <img src="@/assets/images/icon_wait.svg" loading="lazy" alt="" class="image-188">
                            <div class="text-block-225">Tele-Doc Visit</div>
                        </div>
                        <div class="div-block-1114" v-else>
                            <img src="@/assets/images/icon_Check_1.svg" loading="lazy" alt="" class="image-188">
                            <div class="text-block-225">Tele-Doc Visit</div>
                        </div>

                        <div class="div-block-1114" v-if="prescription != null && prescription != undefined">
                            <img src="@/assets/images/icon_Check_1.svg" loading="lazy" alt="" class="image-188">
                            <div class="text-block-225">Prescription Received</div>
                        </div>
                        <div class="div-block-1114" v-else>
                            <img src="@/assets/images/icon_wait.svg" loading="lazy" alt="" class="image-188">
                            <div class="text-block-225">Prescription [Waiting]</div>
                        </div>

                        <div class="div-block-1114" v-if="lastVisit && appointments.status == 1">
                            <img src="@/assets/images/icon_open.svg" loading="lazy" alt="" class="image-188">
                            <div>
                                <div class="text-block-225 b">Schedule Monthly Follow Up Visit</div>
                                <div>
                                    <router-link :to="'/re-visit?health_id=' + user.health_id + '&id=' + user.id + '&state=' + shipping_details.state" target="_blank" class="link-15">Click Here to Schedule Visit
                                        &gt;&gt;</router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="div-block-1115 a">
                        <div class="div-block-1117">
                            <div class="text-block-227">Current Prescription Plan</div>
                            <div class="div-block-1116" v-if="subscription != null">
                                <img src="@/assets/images/icon_check2.svg" loading="lazy" alt="" class="image-189">
                                <div>
                                    <div class="text-block-228">Compound {{ subscription.plan }}</div>
                                </div>
                            </div>
                            <div class="div-block-1116" v-else>
                                <img src="@/assets/images/icon_Urgent.svg" loading="lazy" alt="" class="image-189">
                                <div>
                                    <div class="text-block-228">No Subscription Found</div>
                                </div>
                            </div>

                        </div>
                        <div class="div-block-1117" v-if="prescription != null && prescription != undefined">
                            <div>
                                <div class="text-block-227" style="font-size: 20px;">Medication Instructions</div>
                                <div class="text-block-229" style="margin-left:15px; font-size:17px;">{{
                                        priscriptionInstructions }}</div>
                            </div>
                        </div>
                        <div class="div-block-1117" v-if="subscription != null && medForm!= null && medForm.visit_type=='sync_visit'">
                            <div v-if="appointments == null">
                                <div class="text-block-227">Next Tele-Doc Appointment</div>
                                <div class="text-block-229">No appointment scheduled.</div>
                                <div>
                                    <router-link :to="'/schedule?health_id=' + user.health_id + '&id=' + user.id + '&state=' + shipping_details.state" target="_blank" class="link-15">Click Here to Scheduled
                                        &gt;&gt;</router-link>
                                </div>
                            </div>
                            <div v-else-if="appointments.status == 0">
                                <div class="text-block-227">Next Tele-Doc Appointment</div>
                                <div class="text-block-229">{{ getAppDate(appointments.app_date,
                                        appointments.app_timezone)}}</div>
                                <div>
                                    <router-link :to="'/schedule?health_id=' + user.health_id + '&id=' + user.id + '&state=' + shipping_details.state" target="_blank" class="link-15">Click Here to Update &gt;&gt;</router-link>
                                </div>
                            </div>
                            <div v-else-if="appointments.status == 2">
                                <div class="text-block-227">Missed Tele-Doc Appointment</div>
                                <div>
                                    <router-link :to="'/schedule?health_id=' + user.health_id + '&id=' + user.id + '&state=' + shipping_details.state" target="_blank" class="link-15">Click Here to Reschedule
                                        &gt;&gt;</router-link>
                                </div>
                            </div>
                        </div>

                        <!--<div class="div-block-1117">
                        <div class="text-block-227">Next Billing Period</div>
                        <div class="text-block-229">{{getDateInFormat(subscription.charge_on).split('@')[0]}} -&gt; <span class="text-span-34">${{subscription.amount}}</span></div>
                        <div>
                            <a href="#" class="link-15">Click Here to Update &gt;&gt;</a>
                        </div>
                    </div>-->
                    </div>
                </div>

                <div class="text-block-223"><strong class="bold-text-20">Shipping Address</strong></div>
                <div class="div-block-1118">
                </div>
                <div class="div-block-1112">
                    <div class="div-block-1113">
                        <div>
                            <form @submit.prevent="updateAddress()">
                                <div class="div-block-1119">
                                    <input class="text-field-33 email form w-input" placeholder="Address Line 1" v-model="shipping_details.address_line_1">
                                </div>
                                <div class="div-block-1119">
                                    <input class="text-field-40 height w-input" style="width: 40%" placeholder="Address Line2" type="text" v-model="shipping_details.address_line_2" required>
                                    <input class="text-field-40 height w-input" style="width: 40%" placeholder="City" type="text" v-model="shipping_details.city" required>
                                </div>
                                <div class="div-block-1119">
                                    <select v-model="shipping_details.state" required class="text-field-40 height w-input" style="width: 40%">
                                        <option value="" disabled selected>State...</option>
                                        <option v-for="(value, key) in allstate" :value="value" :key="value">{{ key }} </option>
                                    </select>
                                    <input class="text-field-40 height w-input" style="width: 40%" placeholder="Zip" type="text" v-model="shipping_details.zip" required>
                                </div>
                                <div class="div-block-1119">
                                    <input type="submit" data-wait="Please wait..." class="submit-button-2 b w-button" value="Update Address">
                                </div>
                            </form>
                        </div>
                    </div>

                </div>

                <div class="text-block-223" v-if="order_track && order_track.length > 0"><strong class="bold-text-20">Order Tracking</strong></div>
                <div class="div-block-1118" v-if="order_track && order_track.length > 0">
                </div>
                <div class="div-block-1112" v-if="order_track && order_track.length > 0">
                    <div class="div-block-1113" style="width:100%">
                        <table class="table-container">
                            <thead>
                                <tr>
                                    <th>Type</th>
                                    <th>Order#</th>
                                    <th>Tracking#</th>
                                    <th>Carrier</th>
                                    <th>Date</th>
                                </tr>
                            </thead>
                            <tbody>
                              
                                <tr v-for="order in order_track" :key="order.id">
                                    <td>{{ order?.payload?.type ?? 'N/A' }}</td>
                                    <td>{{ order?.payload?.data?.orderNumber ?? 'N/A' }}</td>
                                    <td v-if="order?.payload?.type === 'order_confirmation'">None</td>
                                    <td v-else>
                                        <a v-if="order?.payload?.data?.tracking_number" :href="getTrackingLink(order.payload.data.tracking_number)" target="_blank">
                                            {{ order.payload.data?.tracking_number }}
                                        </a>
                                        <span v-else>N/A</span>
                                    </td>
                                    <td v-if="order?.payload?.type === 'order_confirmation'">None</td>
                                    <td v-else>{{ detectCarrier(order.payload.data?.tracking_number) }}</td>
                                    <td v-if="order?.payload?.type === 'order_confirmation'">{{ order?.created_at?.split('T')[0] ?? 'N/A' }}</td>
                                    <td v-else>{{ order?.payload?.data?.shipped_date ?? 'N/A' }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                </div>

                <div class="text-block-223"><strong class="bold-text-20">Billing</strong></div>
                <div class="div-block-1118">
                </div>
                <div class="div-block-1112">
                    <div class="div-block-1113">
                        <div class="text-block-230">Past Payments</div>
                        <div v-for="trans in transactions" :key="trans.id">
                            <div class="div-block-1119">
                                <div class="text-block-231">${{ trans.amount }} - {{ getDate(trans.created_at) }} -
                                    <span class="transtatus">{{ trans.status.charAt(0).toUpperCase() +
                                            trans.status.slice(1) }}</span></div>
                                <a href="#" v-if="trans.status == 'success'" @click="getReciept($event, trans.id)" class="link-16">Download Receipt</a>
                            </div>
                        </div>
                    </div>
                    <div class="div-block-1115">
                        <div class="div-block-1117 b" v-if="subscription != null">
                            <div class="text-block-232">Next Payment Date</div>
                            <div class="text-block-229">{{ getDateInFormat(subscription.charge_on) }}</div>
                            <div class="text-block-233">${{ subscription.amount }}</div>
                            <div class="div-block-1120">
                                <a href="#" @click="toggleUpdate($event)" class="button-44 w-button dash-button">Update Credit Card</a>
                                <a href="#" @click.prevent="cancelSubs = true;" class="button-45 w-button dash-button">Cancel Subscription</a>
                            </div>
                        </div>
                        <div class="div-block-1117 b" v-else>
                            <div class="text-block-232">No Subscription Found</div>
                        </div>
                    </div>
                </div>

                <!--refral starts-->
                <div v-if="tracking != null">
                    <div class="text-block-223"><strong class="bold-text-20">Unlock Savings with Our Customer Reward
                            Program!</strong></div>
                    <div class="div-block-1118"></div>
                    <div class="div-block-1112">
                        <div class="div-block-1113">
                            <div class="div-block-1119">
                                <div class="text-block-231">Refer friends to Yara and get 10% off per month for each
                                    active referral. With 10 active customers, your monthly cost is on us! Start
                                    sharing your link today.</div>
                            </div>
                        </div>
                        <div class="div-block-1115">
                            <div class="div-block-1117 b">
                                <div class="text-block-232">Your Referral Link</div>
                                <p>Spread the word! Share your unique referral link on social media, through email,
                                    or in text messages. Every time someone signs up using your link, you’ll receive
                                    a discount. Start sharing and save more with each referral!</p>
                                <div class="text-block-229"> <a :href="'https://ytrk.link/t/' + tracking.link" target="_blank">https://ytrk.link/t/{{ tracking.link }}</a> &nbsp;&nbsp;<a href="#" @click="CopyCode($event, tracking.link)"><img src="@/assets/images/copyicon.svg"></a></div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- ends here -->
            </div>
        </section>
        <!--<section class="floating-links-section">
    <div class="div-block-1199">
      <div class="div-block-1200">
        <a href="#" class="link-block-15 w-inline-block">
          <div class="div-block-1201 _2"><img src="@/assets/images/icon_support.svg" loading="lazy" alt="" class="image-209">
            <div class="text-block-268">CHAT SUPPORT -&gt;</div>
          </div>
        </a>
        <a href="help-portal.html" class="link-block-16 w-inline-block">
          <div class="div-block-1201 _1"><img src="@/assets/images/icon_help.svg" loading="lazy" alt="" class="image-209">
            <div class="text-block-268">HELP PORTAL -&gt;</div>
          </div>
        </a>
      </div>
    </div>
  </section>-->

    </div>
</div>
</template>

<script>
import html2pdf from 'html2pdf.js';

import modelWindow from './modelWindow.vue'
import confirmationWindow from './confirmationWindow.vue'
import tostWindow from './tostWindow.vue'
import moment from 'moment-timezone';
import state from "../utils/state.json"
export default {
    name: 'HomePage',
    components: {

        'update-card': modelWindow,
        'confirm-cancel': confirmationWindow,
        'toast-window': tostWindow
    },
    data() {
        return {
            allstate: state,
            user: [],
            transactions: [],
            baseUrl: "https://serverforge.joinyara.com/dash",
            notrans: false,
            subscription: null,
            appointments: null,
            cancelSubs: false,
            loading: true,
            winkey: 0,
            lab: null,
            prescription: null,
            updateCard: false,
            shipping_details: null,
            toastMessage: '',
            showT: false,
            isopen: false,
            tracking: null,
            order_track: [],
            medForm: null,
        }
    },
    computed: {
        priscriptionInstructions() {
            try {
                let payload = this.prescription.payload;
                let k = payload.find(element => element.label == "Instructions");
                return k.answer;
            } catch (error) {
                console.log(error);
                return "";
            }
        },
        lastVisit() {
            if (this.appointments == null) {
                return false;
            }
            try {
                var startDate = moment(this.appointments.app_date, "YYYY-MM-DDTHH:mm:ssZ");
                var currentDate = moment();
                var daysPast = currentDate.diff(startDate, 'days');
                return daysPast > 21;
            } catch (error) {
                console.log(error);
                return false;
            }
        },
        inFuture() {
            try {
                if (this.medForm != null && this.medForm.visit_type == 'async_visit') {
                    return false;
                }
                if (this.appointments == null) {
                    return true;
                }
                const targetMoment = moment(this.appointments.app_date);
                const currentMoment = moment();
                return targetMoment.isAfter(currentMoment);
            } catch (error) {
                console.log(error);
                return true;
            }

        }
    },
    methods: {
        getTrackingLink(trackingNumber) {
            const upsPattern = /^1Z[0-9A-Z]{16}$/;
            const uspsPattern = /^(94|95|96|97|98|99)[0-9]{18,20}$/;
            const fedexPattern = /^(\d{12}|\d{15})$/;
            const dhlPattern = /^\d{10}$/;

            if (upsPattern.test(trackingNumber)) {
                return `https://www.ups.com/track?track=yes&trackNums=${trackingNumber}`
            } else if (uspsPattern.test(trackingNumber)) {
                return `https://tools.usps.com/go/TrackConfirmAction?qtc_tLabels1=${trackingNumber}`
            } else if (fedexPattern.test(trackingNumber)) {
                return `https://www.fedex.com/fedextrack/?trknbr=${trackingNumber}`
            } else if (dhlPattern.test(trackingNumber)) {
                return `https://www.dhl.com/us-en/home/tracking.html?tracking-id=${trackingNumber}`
            } else {
                return '#';
            }
        },
        detectCarrier(trackingNumber) {
            const upsPattern = /^1Z[0-9A-Z]{16}$/;
            const uspsPattern = /^(94|95|96|97|98|99)[0-9]{18,20}$/;
            const fedexPattern = /^(\d{12}|\d{15})$/;
            const dhlPattern = /^\d{10}$/;

            if (upsPattern.test(trackingNumber)) {
                return 'UPS';
            } else if (uspsPattern.test(trackingNumber)) {
                return 'USPS';
            } else if (fedexPattern.test(trackingNumber)) {
                return 'FedEx';
            } else if (dhlPattern.test(trackingNumber)) {
                return 'DHL';
            } else {
                return 'Unknown carrier';
            }
        },
        updateAddress() {
            let vm = this;
            window.axios.post(this.baseUrl + '/update-shipaddress', this.shipping_details).then(response => {
                if (response.data.status == 'success') {
                    this.toastMessage = "Address updated!"
                    this.showT = true;
                    setTimeout(() => {
                        vm.showT = true;
                    }, 4000);
                } else {
                    this.toastMessage = "Error occurred!"
                    this.showT = true;
                    setTimeout(() => {
                        vm.showT = true;
                    }, 4000);
                }
            });
        },
        CopyCode(event, link) {
            event.preventDefault();
            let vm = this;
            const linkw = 'https://ytrk.link/t/' + link;
            navigator.clipboard.writeText(linkw).then(() => {
                this.toastMessage = "Link Copied!"
                this.showT = true;
                setTimeout(() => {
                    vm.showT = true;
                }, 4000);
            }).catch((error) => {
                console.log(error)
            });
        },
        OpenMenu() {
            this.isopen = !this.isopen;
        },
        sendToPage(event, url) {
            event.preventDefault();
            window.open(url, '_blank');
            this.OpenMenu();
        },
        ShowToastForCancelError(message) {
            this.toastMessage = message
            this.showT = true;
            setTimeout(() => {
                localStorage.removeItem('_ymain_key_');
                window.location.reload();
            }, 10000);
        },
        ShowToastForCancel() {
            this.toastMessage = "Your subscription is cancelled."
            this.showT = true;
            setTimeout(() => {
                localStorage.removeItem('_ymain_key_');
                window.location.reload();
            }, 10000);
        },
        ShowToast() {
            this.toastMessage = "Success. Card updated"
            this.showT = true;
            this.updateCard = false;
        },
        toggleUpdate(event) {
            event.preventDefault();
            this.updateCard = !this.updateCard;
        },
        getReciept(event, id) {
            event.preventDefault();
            window.axios.get(this.baseUrl + '/invoice?id=' + id).then(response => {
                if (response.data.status == 'error') {
                    console.log("error");
                } else {

                    const options = {
                        margin: 0.1, // Set margin to 10mm
                        filename: id + '.pdf', // Specify filename
                        image: {
                            type: 'jpeg',
                            quality: 1
                        }, // Specify image quality
                        html2canvas: {
                            dpi: 192,
                            scale: 10,
                            letterRendering: true,
                            useCORS: true
                        }, // Scale up html2canvas
                        jsPDF: {
                            unit: 'mm',
                            format: 'a4',
                            orientation: 'portrait'
                        } // Set PDF format and orientation
                    };
                    html2pdf().set(options).from(response.data).save();
                    console.log("generated");

                }
            });
        },
        getDate(date) {
            return moment(date).format('MMMM D, YYYY')
        },
        getAppDate(date_of_appointment, timezone) {
            return moment(date_of_appointment).tz(timezone).format('MMMM Do, YYYY @ hh:mm A z')
        },
        getDateInFormat(utcTimestampISO) {
            const userTimeZone = moment.tz.guess(); // Automatically detect user's timezone
            const localDateTime = moment.utc(utcTimestampISO).tz(userTimeZone);
            return moment(localDateTime).format("MMMM Do, YYYY [@] h A z");
        },
        isDataExpired() {
            let storedData = localStorage.getItem('lastSync');
            if (storedData) {
                storedData = JSON.parse(storedData);
                let unixTime = Math.floor(Date.now() / 1000);
                var differenceInSeconds = Math.abs(unixTime - storedData) / 1000;
                var fifteenMinutesInSeconds = 15 * 60;
                return differenceInSeconds > fifteenMinutesInSeconds;
            }
            return true;
        },
        getData() {
            window.axios.get(this.baseUrl + '/transactions').then(response => {
                if (response.data.status == 'success') {
                    this.transactions = response.data.transactions;
                    this.subscription = response.data.subscriptions;
                    this.appointments = response.data.appointment;
                    this.lab = response.data.lab;
                    this.prescription = response.data.priscription;
                    this.shipping_details = response.data.shipping.shipping_details;
                    this.tracking = response.data.tracking;
                    this.order_track = response.data.order_track;
                    this.medForm = response.data.medForm;
                    this.$store.dispatch("saveTransaction", response.data);
                    localStorage.setItem('lastSync', JSON.stringify(Math.floor(Date.now() / 1000)));
                    this.loading = false;
                    this.winkey++;
                } else {
                    this.notrans = true;
                    this.winkey++;
                }
            });
        },
        getTransactions() {
            this.loading = true;
            if (this.isDataExpired()) {
                this.getData();
            } else if (this.$store.getters.transaction == null) {
                this.getData();
            } else {
                this.transactions = this.$store.getters.transaction;
                this.subscription = this.$store.getters.subscription;
                this.appointments = this.$store.getters.appointment;
                this.lab = this.$store.getters.lab;
                this.prescription = this.$store.getters.prescription;
                this.shipping_details = this.$store.getters.shipping.shipping_details;
                this.tracking = this.$store.getters.tracking;
                this.order_track = this.$store.getters.order_track;
                this.medForm = this.$store.getters.medForm;
                this.loading = false;
                this.winkey++;
            }
        },
        Logout(event) {
            event.preventDefault();
            window.axios.get(this.baseUrl + '/logout').then(response => {
                if (response.data.status == 'success') {
                    localStorage.removeItem('_ymain_key_');
                    window.location.reload();
                }
            }).catch(error => {
                console.log(error);
                localStorage.removeItem('_ymain_key_');
                window.location.reload();
            });
        }
    },
    created() {
        this.user = this.$store.getters.user;
        this.getTransactions();
    }
}
</script>

<style scoped>
.dash-button {
    height: 45px;
}

span.transtatus:first-letter {
    text-transform: uppercase;
}

.displaymenu {
    height: 2612.12px;
    display: block;
}

.trans {
    transform: translateY(0px) translateX(0px);
    transition: transform 400ms ease 0s;
}

.table-container {
    overflow-x: auto;
    width: 100%;
    max-width: 800px;
    /* Set max width or adjust as needed */
}

table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
    font-size: 14px;
}

th,
td {
    border: 1px solid #ccc;
    padding: 8px;
    text-align: left;
}

th {
    background-color: #f2f2f2;
    cursor: col-resize;
    /* Cursor changes to resize when hovering over headers */
}
</style>
