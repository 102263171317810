<template>
    <div>
        <div>
            <div class="form-3-header-div">
                <div class="div-block-966 presell">
                    <a href="#" class="w-inline-block"><img loading="lazy" src="https://uploads-ssl.webflow.com/657b2233d6a286f5c9f3844c/65a8b55c0b759f07aa5e3058_yara%20placeholder.svg" alt="" class="image-30"></a>
                </div>
            </div>
        </div>
        <section>
            <div class="div-block-1668">
                <div class="w-layout-blockcontainer container-23 w-container">
                    <div class="div-block-1671">
                        <div class="text-block-736">You are all set {{ first_name }}!</div>
                        <p class="paragraph-127">Based on your responses, the initial <strong>virtual doctor visit is not
                                required.</strong><br></p>
                    </div>
                    <div class="form-block-6 w-form">
                        <form id="email-form" name="email-form" data-name="Email Form" method="get" class="form-9" data-wf-page-id="66e2d63ba58948490f7db1a8" data-wf-element-id="6cb9b46d-c1aa-68ad-5aae-6d38c5369ee5">
                            <div class="bullet-points">
                                <div class="div-block-1674"><img src="@/assets/images/stepsimage_teledoc.png" loading="lazy" sizes="(max-width: 991px) 40px, 100vw" srcset="@/assets/images/stepsimage_teledoc-p-500.png 500w, @/assets/images/stepsimage_teledoc.png 641w" alt="" class="image-384">
                                    <div>
                                        <div class="text-block-735">Doctor will review &amp; prescribe.</div>
                                        <div class="text-block-737">Our clinicians will review your info &amp; send script to partner
                                            pharmacy.</div>
                                    </div>
                                </div>
                                <div class="div-block-1674"><img src="@/assets/images/icon_van.png" loading="lazy" alt="" class="image-384">
                                    <div>
                                        <div class="text-block-735">Express Shipping</div>
                                        <div class="text-block-737">We will ship your medication express so you can start ASAP!</div>
                                    </div>
                                </div>
                                <div class="div-block-1674"><img src="@/assets/images/icon_doc.png" loading="lazy" alt="" class="image-384">
                                    <div>
                                        <div class="text-block-735">Unlimited Clinical Support</div>
                                        <div class="text-block-737">Once the medicine arrives you will gain unlimited access to doctors by
                                            phone &amp; email to answer any questions.</div>
                                    </div>
                                </div>
                                
                            </div>
                            <div class="div-block-1676">
                                <a href="/login" class="button-79 w-button">Log in to Dashboard</a>
                            </div>
                        </form>
                        <div class="w-form-done">
                            <div>Thank you! Your submission has been received!</div>
                        </div>
                        <div class="w-form-fail" style="display: block;" v-if="error">
                            <div>{{errorMessage}}</div>
                        </div>
                    </div>
                    <div class="div-block-1667"></div>
                </div>
            </div>
        </section>
    </div>
    </template>
    
<script>

export default {
    name: 'HelloWorld',
    props: ['baseUrl'],
    components: {
      
    },
    data() {
        return {
            selTime: "",
            setDate: '',
        }
    },
    computed:{
        first_name(){
            try{
                let k = localStorage.getItem("formResponse");
                if(k){
                    let temp = JSON.parse(k);
                    return temp.first_name;
                }
            }catch(error){
                console.log(error);
                
            }
            return "";
        },
        
    },
    created() {
    }
}
</script>
    
    