var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"background-color":"#fff"}},[_c('section',[_c('div',{staticClass:"hero-div"},[_vm._m(0),_c('div',{staticClass:"hero-content-div"},[_c('div',{staticClass:"hero-left"},[_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_c('a',{staticClass:"button-60 w-button",attrs:{"href":"#"},on:{"click":function($event){return _vm.pushToRoute($event)}}},[_vm._v("Get Started With $200 Off ->")]),_vm._m(6)]),_c('div',{staticClass:"hero-right"},[_c('div',{staticClass:"savings-main-div"},[_c('div',{staticClass:"div-block-1431"},[_c('img',{staticClass:"image-292",attrs:{"src":require("@/assets/images/party-popper.png"),"loading":"lazy","alt":""}}),_c('div',[_c('div',{staticClass:"text-block-492"},[_c('span',{staticClass:"text-span-99"},[_c('sup',[_vm._v("$")]),_vm._v(_vm._s(214 + _vm.discount)+" total savings")]),_vm._v(" to start your Semaglutide weight loss journey!")])])]),_c('div',{staticClass:"discount-item-div"},[_vm._m(7),_c('div',{staticClass:"savings-price-div"},[_vm._m(8),_c('div',{staticClass:"text-block-496"},[_c('sup',[_vm._v("$")]),_vm._v(_vm._s(299 - _vm.discount))])])]),_vm._m(9),_vm._m(10),_vm._m(11)])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hero-logo-div"},[_c('div',{staticClass:"div-block-1386 _100"},[_c('img',{staticClass:"image-283",attrs:{"src":require("@/assets/images/yara-placeholder-W.svg"),"loading":"lazy","alt":""}}),_c('div',{staticClass:"text-block-452"},[_vm._v("$")]),_c('div',{staticClass:"text-block-453"},[_vm._v("200")]),_c('div',{staticClass:"text-block-454"},[_vm._v("off!")]),_c('img',{staticClass:"image-284",attrs:{"src":require("@/assets/images/icon-check.svg"),"loading":"lazy","alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-block-455"},[_vm._v(" Get "),_c('span',{staticClass:"text-span-98"},[_c('sup',[_vm._v("$")]),_vm._v("200 off")]),_vm._v(" today on any Yara prescription weight loss program.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"div-block-1428"},[_c('div',{staticClass:"text-block-489"},[_vm._v("Coupon Code")]),_c('div',{staticClass:"text-block-490"},[_vm._v("WeightLoss200")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"div-block-1429"},[_c('div',{staticClass:"text-block-491"},[_vm._v("What's next:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"div-block-1387"},[_c('img',{staticClass:"image-291",attrs:{"src":require("@/assets/images/icon_check2.svg"),"loading":"lazy","alt":""}}),_c('div',[_c('div',{staticClass:"text-block-457"},[_vm._v("Lock in limited time offer.")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"div-block-1387"},[_c('img',{staticClass:"image-291",attrs:{"src":require("@/assets/images/icon_check2.svg"),"loading":"lazy","alt":""}}),_c('div',[_c('div',{staticClass:"text-block-457"},[_vm._v("Lose Weight Guaranteed."),_c('br')])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"countdown-div-2"},[_c('div',{staticClass:"text-block-459"},[_vm._v("This Offer Expires In:")]),_c('div',{staticClass:"div-block-1389",attrs:{"id":"hereitwill"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"div-block-1432"},[_c('img',{staticClass:"image-293",attrs:{"src":require("@/assets/images/icon_vial-check.svg"),"loading":"lazy","alt":""}}),_c('div',{staticClass:"description-div"},[_c('div',{staticClass:"text-block-493"},[_vm._v("First Month Supply of Semaglutide with "),_c('sup',[_vm._v("$")]),_vm._v("200 discount ")]),_c('div',{staticClass:"text-block-494"},[_vm._v("Month 2 onwards $299")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-block-495"},[_c('sup',[_vm._v("$")]),_vm._v("299")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"discount-item-div"},[_c('div',{staticClass:"div-block-1432"},[_c('img',{staticClass:"image-293",attrs:{"src":require("@/assets/images/icon_doctor-check.svg"),"loading":"lazy","alt":""}}),_c('div',{staticClass:"description-div"},[_c('div',{staticClass:"text-block-493"},[_vm._v("Doctor Support and Monitoring")])])]),_c('div',{staticClass:"savings-price-div"},[_c('div',{staticClass:"text-block-495"},[_c('sup',[_vm._v("$")]),_vm._v("99")]),_c('div',{staticClass:"text-block-496"},[_vm._v("FREE")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"discount-item-div"},[_c('div',{staticClass:"div-block-1432"},[_c('img',{staticClass:"image-293",attrs:{"src":require("@/assets/images/icon_testing-check.svg"),"loading":"lazy","alt":""}}),_c('div',{staticClass:"description-div"},[_c('div',{staticClass:"text-block-493"},[_vm._v("Metabolic testing and blood work included")])])]),_c('div',{staticClass:"savings-price-div"},[_c('div',{staticClass:"text-block-495"},[_c('sup',[_vm._v("$")]),_vm._v("75")]),_c('div',{staticClass:"text-block-496"},[_vm._v("FREE")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"discount-item-div"},[_c('div',{staticClass:"div-block-1432"},[_c('img',{staticClass:"image-293",attrs:{"src":require("@/assets/images/icon_delivery-check.svg"),"loading":"lazy","alt":""}}),_c('div',{staticClass:"description-div"},[_c('div',{staticClass:"text-block-493"},[_vm._v("Express Shipping")])])]),_c('div',{staticClass:"savings-price-div"},[_c('div',{staticClass:"text-block-495"},[_c('sup',[_vm._v("$")]),_vm._v("40")]),_c('div',{staticClass:"text-block-496"},[_vm._v("FREE")])])])
}]

export { render, staticRenderFns }