<template>
<div>
    <loading v-if="loading" />
    <div>
        <div class="form-3-header-div">
            <div class="div-block-966">
                <a href="#" class="w-inline-block"><img loading="lazy" :src="mainlogo" alt="" class="image-30"></a>
                <div class="div-block-967">
                    <div class="text-block-152 top">STEP</div>
                    <div class="text-block-151">{{ questionId }} <span class="text-span-72">of</span> 17</div>
                    <div class="text-block-152 bottom">COMPLETE</div>
                </div>
            </div>
        </div>
        <div class="div-block-181">
            <div class="girl-div"></div>
        </div>
    </div>
    <div>
        <div class="div-block-225">
            <div class="slide-1" v-bind:class="{ 'hide-element': questionId !== 1 }">
                <div class="div-block-226">
                    <h1 class="heading-39 form"><span class="text-span-69">Hey! I&#x27;m Yara.</span><br><span v-html="headings"></span></h1>
                    
                    <div class="div-block-1102" v-if="altpoints">
                        <div class="div-block-1101"><img loading="lazy" :src="getIconPath('icon_pencil2')" alt="" class="image-186">
                            <div class="text-block-221"><strong>Fill out Basic info</strong> (1 min)</div>
                        </div>
                        <div class="div-block-1101"><img loading="lazy" :src="getIconPath('icon_meds')" alt="" class="image-186">
                            <div class="text-block-221"><strong>Quick Eligibility Check</strong> (1 min)</div>
                        </div>
                        <div class="div-block-1101"><img loading="lazy" :src="getIconPath('icon_support')" alt="" class="image-186">
                            <div class="text-block-221 long"><strong>Meet with Care Navigator.</strong></div>
                        </div>
                        <div class="div-block-1101"><img loading="lazy" :src="getIconPath('icon_best')" alt="" class="image-186">
                            <div class="text-block-221 long"><strong>Find the right plan for you.</strong></div>
                        </div>
                    </div>
                    <div class="div-block-1102" v-else>
                        <div class="div-block-1101"><img loading="lazy" :src="getIconPath('icon_pencil2')" alt="" class="image-186">
                            <div class="text-block-221"><strong>Basic info</strong> (1 min)</div>
                        </div>
                        <div class="div-block-1101"><img loading="lazy" :src="getIconPath('icon_meds')" alt="" class="image-186">
                            <div class="text-block-221"><strong>Medical history</strong> (1 min)</div>
                        </div>
                        <div class="div-block-1101"><img loading="lazy" :src="getIconPath('icon_support')" alt="" class="image-186">
                            <div class="text-block-221 long"><strong>Meet with doctor online</strong></div>
                        </div>
                        <div class="div-block-1101"><img loading="lazy" :src="getIconPath('icon_best')" alt="" class="image-186">
                            <div class="text-block-221 long"><strong>Get meds at door in 3 Days</strong></div>
                        </div>
                    </div>
                    <a href="#" class="form-button-1 w-button" @click.prevent="questionId = 2">YES. LET&#x27;S DO IT!</a>
                </div>
            </div>

            <div class="slide-2" v-bind:class="{ 'hide-element': questionId !== 2 }">
                <h1 class="heading-39">Who do I have the pleasure of talking to?</h1>
                <div class="form-block-3 w-form">
                    <form id="email-form" name="email-form" @submit.prevent="questionId++" class="form-3" data-wf-page-id="668d55c8f0b55e9fc41fd2be" data-wf-element-id="f8850746-b7ef-edba-42d4-5094f7ee5d30">
                        <div class="div-block-965"><input class="text-field-33 w-input" maxlength="256" name="name" data-name="Name" placeholder="First Name" type="text" v-model="people.first_name" id="name" required><input class="text-field-33 w-input" maxlength="256" name="Last-name" data-name="Last Name" v-model="people.last_name" placeholder="Last Name" type="text" id="Last-name" required></div><input type="submit" data-wait="Please wait..." class="submit-button-2 w-button" value="Next -&gt;">
                    </form>

                </div>
                <div class="back-link-div">
                    <a href="#" class="link-block-4 w-inline-block" @click.prevent="questionId--">
                        <div class="text-block-146">&lt; Go Back</div>
                    </a>
                </div>
            </div>
            <div class="slide6" v-bind:class="{ 'hide-element': questionId !== 3 }">
                <h1 class="heading-39 gender">What was your gender at birth?<br></h1>
                <div class="form-block-3 w-form">
                    <form id="email-form" name="email-form" class="form-3">
                        <div class="div-block-965 gender">
                            <a href="#" @click.prevent="setValueForGender('Male')" class="link-block-23 w-inline-block"><img src="@/assets/images/Male.svg" loading="lazy" alt="" class="image-274">
                                <div class="text-block-419">Male</div>
                            </a>
                            <a href="#" @click.prevent="setValueForGender('Female')" class="link-block-23 w-inline-block"><img src="@/assets/images/Female.svg" loading="lazy" alt="" class="image-274">
                                <div class="text-block-419">Female</div>
                            </a>
                        </div>
                    </form>

                </div>
                <div class="back-link-div">
                    <a href="#" class="link-block-4 w-inline-block" @click.prevent="questionId--">
                        <div class="text-block-146">&lt; Go Back</div>
                    </a>
                </div>
            </div>
            <div class="slide12" v-bind:class="{ 'hide-element': questionId !== 4 }">
                <h1 class="heading-39 birth">What is your date of birth?</h1>
                <p class="paragraph-79">Based on your date of birth our doctors can prescribe the proper prescription.</p>
                <div class="form-block-3 b w-form">
                    <form id="email-form" name="email-form" @submit.prevent="ValidateDOB()" class="form-3 b">
                        <div class="div-block-965 b">
                            <div class="div-block-374 mobile-entry form _2 birth"><select id="birth-month-2" name="birth-month-2" data-name="Birth Month 2" class="select-field-5 w-select" v-model="people.dob.mm" required>
                                    <option value="">Month</option>
                                    <option value="1">January</option>
                                    <option value="2">February</option>
                                    <option value="3">March</option>
                                    <option value="4">April</option>
                                    <option value="5">May</option>
                                    <option value="6">June</option>
                                    <option value="7">July</option>
                                    <option value="8">August</option>
                                    <option value="9">September</option>
                                    <option value="10">October</option>
                                    <option value="11">November</option>
                                    <option value="12">December</option>
                                </select>
                                <div class="text-block-142">-</div><input class="phone-number-field form birth w-input" pattern="[0-9]*" maxlength="2" name="birth-day-2" data-name="Birth Day 2" v-bind:class="{ 'text-danger': dobError }" placeholder="Day" type="text" id="birth-day-2" v-model="people.dob.dd" required>
                                <div class="text-block-142">-</div><input class="phone-number-field _2 form birth w-input" pattern="[0-9]*" maxlength="4" name="birth-year-2" v-model="people.dob.yyyy" v-bind:class="{ 'text-danger': dobError }" data-name="Birth Year 2" placeholder="Year" type="text" id="birth-year-2" required>
                            </div>
                        </div><input type="submit" data-wait="Please wait..." class="submit-button-2 b w-button" value="Next -&gt;">
                    </form>

                </div>
                <div class="back-link-div">
                    <a href="#" class="link-block-4 w-inline-block" @click.prevent="questionId--">
                        <div class="text-block-146">&lt; Go Back</div>
                    </a>
                </div>
            </div>
            <div class="slide12" v-bind:class="{ 'hide-element': questionId !== 5 }">
                <h1 class="heading-39">What is your height and weight?</h1>
                <p class="paragraph-79">We&#x27;ll use this to determine your Body mass Index (BMI) for diagnosis. remember,
                    BMI is a measure of size - not health</p>
                <div class="form-block-3 b w-form">
                    <form id="email-form" name="email-form" @submit.prevent="calculateBMI()" class="form-3 b">
                        <div class="div-block-965 b">
                            <div class="div-block-374 mobile-entry form _2 height top">
                                <input class="text-field-40 height w-input" pattern="[0-9]*" v-model="people.height.ft" maxlength="2" name="feet" data-name="Feet" placeholder="Feet" type="text" id="feet" required>
                                <div class="text-block-343">ft.</div><input class="text-field-40 height w-input" v-model="people.height.inch" pattern="[0-9]*" maxlength="2" name="inches" data-name="Inches" placeholder="Inches" type="text" id="inches" required>
                                <div class="text-block-344">in.</div>
                            </div>
                            <div class="div-block-374 mobile-entry form _2 weight"><input class="text-field-40 weight w-input" v-model="people.weight" pattern="[0-9]*" maxlength="4" name="weight-2" data-name="Weight 2" placeholder="Weight in pounds" type="text" id="weight-2" required>
                                <div class="text-block-343 weight">lbs.</div>
                            </div>
                        </div><input type="submit" data-wait="Please wait..." class="submit-button-2 b w-button" value="Next -&gt;">
                    </form>

                </div>
                <div class="back-link-div">
                    <a href="#" class="link-block-4 w-inline-block" @click.prevent="questionId--">
                        <div class="text-block-146">&lt; Go Back</div>
                    </a>
                </div>
            </div>
            <div class="slide12" v-bind:class="{ 'hide-element': questionId !== 6 }">
                <h1 class="heading-39">Ok so if you weigh <strong>{{ people.weight }} lbs. now...<br></strong>What is your
                    <strong>desired weight </strong>?
                </h1>
                <div class="form-block-3 b w-form">
                    <form id="email-form" name="email-form" class="form-3 b" @submit.prevent="questionId++">
                        <div class="div-block-965 b">
                            <div class="div-block-374 mobile-entry form _2 weight"><input class="text-field-40 weight w-input" pattern="[0-9]*" maxlength="3" v-model="people.desired_weight" name="Goal-Weight" data-name="Goal Weight" placeholder="What is your goal weight in pounds?" type="text" id="Goal-Weight" required>
                                <div class="text-block-343 weight">lbs.</div>
                            </div>
                        </div><input type="submit" data-wait="Please wait..." class="submit-button-2 b w-button" value="Next -&gt;">
                    </form>

                </div>
                <div class="back-link-div">
                    <a href="#" class="link-block-4 w-inline-block" @click.prevent="questionId--">
                        <div class="text-block-146">&lt; Go Back</div>
                    </a>
                </div>
            </div>
            <div class="slide12" v-bind:class="{ 'hide-element': questionId !== 7 }">
                <h1 class="heading-39">How long have you had concerns about your weight?</h1>
                <p class="paragraph-79">Understanding if there&#x27;s been a recent change will help us make an more accurate
                    diagnosis.</p>
                <div class="form-block-3 w-form">
                    <form id="email-form" name="email-form" class="form-3">
                        <div class="div-block-965">
                            <a href="#" @click.prevent="setAttributeValue('concerned_about_weight_for', 'Less than 6 months')" class="button-55 _4choice w-button">Less than 6 months</a>
                            <a href="#" @click.prevent="setAttributeValue('concerned_about_weight_for', '6-12 months')" class="button-55 _4choice w-button">6-12 months</a>
                        </div>
                        <div class="div-block-965">
                            <a href="#" @click.prevent="setAttributeValue('concerned_about_weight_for', '1-5 years')" class="button-55 w-button">1-5 years</a>
                            <a href="#" @click.prevent="setAttributeValue('concerned_about_weight_for', 'More than 5 years')" class="button-55 w-button">More than 5 years</a>
                        </div>
                    </form>

                </div>
                <div class="back-link-div">
                    <a href="#" class="link-block-4 w-inline-block" @click.prevent="questionId--">
                        <div class="text-block-146">&lt; Go Back</div>
                    </a>
                </div>
            </div>
            <div class="slide12" v-bind:class="{ 'hide-element': questionId !== 8 }">
                <h1 class="heading-39 mental-health">Does your weight negatively affect your physical or mental health?</h1>
                <div class="form-block-3 w-form">
                    <form id="email-form" name="email-form" class="form-3">
                        <div class="div-block-965">
                            <a href="#" @click.prevent="setAttributeValue('weight_negatively_affecting_physical_mental_health', 'Yes, a lot')" class="button-55 _4choice w-button">Yes, a lot</a>
                            <a href="#" @click.prevent="setAttributeValue('weight_negatively_affecting_physical_mental_health', 'Yes, somewhat')" class="button-55 _4choice w-button">Yes, somewhat</a>
                        </div>
                        <div class="div-block-965">
                            <a href="#" @click.prevent="setAttributeValue('weight_negatively_affecting_physical_mental_health', 'Not much')" class="button-55 w-button">Not much</a>
                            <a href="#" @click.prevent="setAttributeValue('weight_negatively_affecting_physical_mental_health', 'Not at all')" class="button-55 w-button">Not at all<br></a>
                        </div>
                    </form>

                </div>
                <div class="back-link-div">
                    <a href="#" class="link-block-4 w-inline-block" @click.prevent="questionId--">
                        <div class="text-block-146">&lt; Go Back</div>
                    </a>
                </div>
            </div>
            <!--<div class="slide12" v-bind:class="{ 'hide-element': questionId !== 9 }">
          <h1 class="heading-39">What is your weight loss goal?</h1>
          <div class="form-block-3 w-form">
            <form id="email-form" name="email-form" class="form-3">
              <div class="div-block-965">
                <a href="#" class="button-55 _4choice w-button" @click.prevent="setAttributeValue('weight_loss_goal','Lose 1-20 Pounds')">Lose 1-20 Pounds</a>
                <a href="#" class="button-55 _4choice w-button" @click.prevent="setAttributeValue('weight_loss_goal','Lose 21-50 Pounds')">Lose 21-50 Pounds</a>
              </div>
              <div class="div-block-965">
                <a href="#" class="button-55 w-button" @click.prevent="setAttributeValue('weight_loss_goal','Lose over 50 Lbs')">Lose over 50 Lbs</a>
                <a href="#" class="button-55 w-button" @click.prevent="setAttributeValue('weight_loss_goal','Havent decided')">Haven’t decided<br></a>
              </div>
            </form>
            
          </div>
          <div class="back-link-div">
            <a href="#" class="link-block-4 w-inline-block" @click.prevent="questionId--">
              <div class="text-block-146">&lt; Go Back</div>
            </a>
          </div>
        </div>-->
            <div class="slide4" v-bind:class="{ 'hide-element': questionId !== 9 }">
                <h1 class="heading-39">What goals are you looking to accomplish?</h1>
                <div class="form-block-3 w-form">
                    <form id="email-form" name="email-form" @submit.prevent="questionId++" class="form-3" data-wf-page-id="668d55c8f0b55e9fc41fd2be" data-wf-element-id="9af9cf54-db20-edf7-773c-86070ebb0f59">
                        <div class="div-block-965"><label class="w-checkbox checkbox-field-4"><input type="checkbox" value="Lose Weight" v-model="goals_are_you_looking_to_accomplish" class="w-checkbox-input checkbox"><span class="checkbox-label-4 w-form-label" for="checkbox-2">Lose
                                    Weight</span></label><label class="w-checkbox checkbox-field-4"><input type="checkbox" value="Improve General Health" v-model="goals_are_you_looking_to_accomplish" class="w-checkbox-input checkbox"><span class="checkbox-label-4 w-form-label" for="checkbox-2">Improve General Health</span></label></div>
                        <div class="div-block-965"><label class="w-checkbox checkbox-field-4"><input type="checkbox" value="Look Better" v-model="goals_are_you_looking_to_accomplish" class="w-checkbox-input checkbox"><span class="checkbox-label-4 w-form-label" for="checkbox-2">Look
                                    Better</span></label><label class="w-checkbox checkbox-field-4"><input type="checkbox" value="Improve Confidence" v-model="goals_are_you_looking_to_accomplish" class="w-checkbox-input checkbox"><span class="checkbox-label-4 w-form-label" for="checkbox-2">Improve Confidence</span></label></div>
                        <div class="div-block-965"><label class="w-checkbox checkbox-field-4">
                                <input type="checkbox" v-model="goals_are_you_looking_to_accomplish" value="Increase Energy" class="w-checkbox-input checkbox">
                                <span class="checkbox-label-4 w-form-label" for="checkbox-2">Increase Energy</span></label></div>
                        <input type="submit" data-wait="Please wait..." class="submit-button-2 w-button" value="Next -&gt;">
                    </form>

                </div>
                <div class="back-link-div">
                    <a href="#" class="link-block-4 w-inline-block" @click.prevent="questionId--">
                        <div class="text-block-146">&lt; Go Back</div>
                    </a>
                </div>
            </div>
            <div class="slide4" v-bind:class="{ 'hide-element': questionId !== 10 }">
                <h1 class="heading-39">What have you tried in the past?</h1>
                <div class="form-block-3 w-form">
                    <form id="email-form" name="email-form" @submit.prevent="questionId++" class="form-3" data-wf-page-id="668d55c8f0b55e9fc41fd2be" data-wf-element-id="49775ee3-f005-20e9-176c-56f18796cc28">
                        <div class="div-block-965"><label class="w-checkbox checkbox-field-4"><input type="checkbox" v-model="tried_in_past" value="Exercise" class="w-checkbox-input checkbox"><span class="checkbox-label-4 w-form-label" for="checkbox-2">Exercise</span></label><label class="w-checkbox checkbox-field-4"><input type="checkbox" value="Dieting" name="checkbox-2" v-model="tried_in_past" class="w-checkbox-input checkbox"><span class="checkbox-label-4 w-form-label" for="checkbox-2">Dieting</span></label></div>
                        <div class="div-block-965"><label class="w-checkbox checkbox-field-4"><input type="checkbox" v-model="tried_in_past" class="w-checkbox-input checkbox" value="Weight Loss Supplements"><span class="checkbox-label-4 w-form-label" for="checkbox-2">Weight Loss Supplements</span></label><label class="w-checkbox checkbox-field-4"><input type="checkbox" v-model="tried_in_past" class="w-checkbox-input checkbox"><span class="checkbox-label-4 w-form-label" for="checkbox-2">Medical weight loss program</span></label></div>
                        <div class="div-block-965"><label class="w-checkbox checkbox-field-4"><input type="checkbox" v-model="tried_in_past" class="w-checkbox-input checkbox" value="Intermittent Fasting"><span class="checkbox-label-4 w-form-label" for="checkbox-2">Intermittent Fasting</span></label></div>
                        <input type="submit" data-wait="Please wait..." class="submit-button-2 w-button" value="Next -&gt;">
                    </form>

                </div>
                <div class="back-link-div">
                    <a href="#" class="link-block-4 w-inline-block" @click.prevent="questionId--">
                        <div class="text-block-146">&lt; Go Back</div>
                    </a>
                </div>
            </div>
            <!--<div class="slide3" v-bind:class="{ 'hide-element': questionId !== 12 }">
          <h1 class="heading-39">Have you had prior bariatric (weight loss) surgery or any abdominal/pelvic surgeries?
          </h1>
          <div class="form-block-3 w-form">
            <form id="email-form" name="email-form" data-name="Email Form" method="get" class="form-3"
              data-wf-page-id="668d55c8f0b55e9fc41fd2be" data-wf-element-id="f8850746-b7ef-edba-42d4-5094f7ee5da0">
              <div class="div-block-965">
                <a href="#"  @click.prevent="setValueForSurgery('Yes')" class="button-55 w-button">YES</a>
                <a href="#"  @click.prevent="setValueForSurgery('No')" class="button-55 w-button">NO</a>
              </div>
            </form>
          </div>
          <div class="back-link-div">
            <a href="#" class="link-block-4 w-inline-block" @click.prevent="questionId--">
              <div class="text-block-146">&lt; Go Back</div>
            </a>
          </div>
        </div>
        <div class="slide3" v-bind:class="{ 'hide-element': questionId !== 12 }">
          <h1 class="heading-39">Do you have any major medical conditions?</h1>
          <div class="form-block-3 w-form">
            <form id="email-form" name="email-form" data-name="Email Form" method="get" class="form-3"
              data-wf-page-id="668d55c8f0b55e9fc41fd2be" data-wf-element-id="f8850746-b7ef-edba-42d4-5094f7ee5db4">
              <div class="div-block-965">
                <a href="#"  @click.prevent="setAttributeValue('major_medical_conditions','Yes')" class="button-55 w-button">YES</a>
                <a href="#"  @click.prevent="setAttributeValue('major_medical_conditions','No')" class="button-55 w-button">NO</a>
              </div>
            </form>
            <div class="w-form-done">
              <div>Thank you! Your submission has been received!</div>
            </div>
            <div class="w-form-fail">
              <div>Oops! Something went wrong while submitting the form.</div>
            </div>
          </div>
          <div class="back-link-div">
            <a href="#" class="link-block-4 w-inline-block" @click.prevent="questionId--">
              <div class="text-block-146">&lt; Go Back</div>
            </a>
          </div>
        </div>-->
            <div class="slide4" v-bind:class="{ 'hide-element': questionId !== 11 }">
                <h1 class="heading-39">Do you have any of these medical conditions?</h1>
                <div class="form-block-3 w-form">
                    <form id="email-form" name="email-form" @submit.prevent="checkMedicalCondition()" class="form-3">
                        <div class="disclaimer-box">
                            <div class="div-block-1358">
                                <div class="text-block-418">!</div>
                            </div>
                            <p class="paragraph-93">Any of these complications may disqualify you from weight loss medication
                                approval. <strong>&quot;None&quot; is the best answer.</strong></p>
                        </div>
                        <a href="#" @click.prevent="setAttributeValue('medical_condition', 'None')" class="button-55 full-width w-button">None (Best Answer) <span class="text-span-92">-&gt;</span></a>
                        <div class="div-block-965" v-if="people.gender == 'Female'"><label class="w-checkbox checkbox-field-4"><input type="checkbox" v-model="medical_condition" value="Pregnant" class="w-checkbox-input checkbox"><span class="checkbox-label-4 w-form-label" for="checkbox-2">Pregnant</span></label><label class="w-checkbox checkbox-field-4"><input type="checkbox" v-model="medical_condition" value="Breast Feeding" class="w-checkbox-input checkbox"><span class="checkbox-label-4 w-form-label" for="checkbox-2">Breast Feeding</span></label></div>
                        <div class="div-block-965"><label class="w-checkbox checkbox-field-4"><input type="checkbox" value="Type 2 diabetes ON INSULIN" v-model="medical_condition" class="w-checkbox-input checkbox"><span class="checkbox-label-4 w-form-label" for="checkbox-2">Type
                                    2 diabetes ON
                                    INSULIN</span></label><label class="w-checkbox checkbox-field-4"><input type="checkbox" value="Type 1 diabetes" v-model="medical_condition" class="w-checkbox-input checkbox"><span class="checkbox-label-4 w-form-label" for="checkbox-2">Type 1 diabetes</span></label></div>
                        <div class="div-block-965"><label class="w-checkbox checkbox-field-4"><input type="checkbox" value="Diabetic retinopathy" v-model="medical_condition" class="w-checkbox-input checkbox"><span class="checkbox-label-4 w-form-label" for="checkbox-2">Diabetic retinopathy</span></label><label class="w-checkbox checkbox-field-4"><input type="checkbox" v-model="medical_condition" value="End-stage liver disease (cirrhosis)" class="w-checkbox-input checkbox"><span class="checkbox-label-4 w-form-label" for="checkbox-2">End-stage liver disease
                                    (cirrhosis)</span></label></div>
                        <div class="div-block-965"><label class="w-checkbox checkbox-field-4"><input type="checkbox" value="End-stage kidney disease (on or about to be on dialysis)" v-model="medical_condition" class="w-checkbox-input checkbox"><span class="checkbox-label-4 w-form-label" for="checkbox-2">End-stage kidney disease (on or about to be
                                    on dialysis)</span></label><label class="w-checkbox checkbox-field-4"><input type="checkbox" value="Current or prior eating disorder" v-model="medical_condition" class="w-checkbox-input checkbox"><span class="checkbox-label-4 w-form-label" for="checkbox-2">Current or prior eating disorder
                                    (anorexia/bulimia)</span></label></div>
                        <div class="div-block-965"><label class="w-checkbox checkbox-field-4"><input type="checkbox" value="Current suicidal thoughts and/or prior suicidal attempt" v-model="medical_condition" class="w-checkbox-input checkbox"><span class="checkbox-label-4 w-form-label" for="checkbox-2">Current suicidal thoughts and/or prior suicidal attempt</span></label><label class="w-checkbox checkbox-field-4">
                                <input type="checkbox" value="History of organ transplant on anti-rejection medication" v-model="medical_condition" class="w-checkbox-input checkbox"><span class="checkbox-label-4 w-form-label" for="checkbox-2">History of organ transplant on anti-rejection
                                    medication</span></label></div>
                        <div class="div-block-965"><label class="w-checkbox checkbox-field-4"><input type="checkbox" value="Severe gastrointestinal condition" v-model="medical_condition" class="w-checkbox-input checkbox"><span class="checkbox-label-4 w-form-label" for="checkbox-2">Severe gastrointestinal condition
                                    (gastroparesis, blockage, inflammatory bowel disease)</span></label><label class="w-checkbox checkbox-field-4"><input type="checkbox" value="Personal or family history of thyroid cyst/nodule, thyroid cancer, medullary thyroid carcinoma, or multiple endocrine neoplasia syndrome type 2" v-model="medical_condition" class="w-checkbox-input checkbox"><span class="checkbox-label-4 w-form-label" for="checkbox-2">Personal or family history of thyroid
                                    cyst/nodule, thyroid cancer, medullary thyroid carcinoma, or multiple endocrine neoplasia syndrome
                                    type 2</span></label></div>
                        <div class="div-block-965"><label class="w-checkbox checkbox-field-4"><input type="checkbox" v-model="medical_condition" class="w-checkbox-input checkbox" value="History of or current pancreatitis"><span class="checkbox-label-4 w-form-label" for="checkbox-2"><strong>History of or current
                                        pancreatitis</strong></span></label>
                            <label class="w-checkbox checkbox-field-4"><input type="checkbox" v-model="medical_condition" class="w-checkbox-input checkbox" value="Had prior bariatric (weight loss) surgery or any abdominal/pelvic surgeries"><span class="checkbox-label-4 w-form-label" for="checkbox-2"><strong>Had prior bariatric (weight loss)
                                        surgery or any abdominal/pelvic surgeries</strong></span></label>
                        </div><input type="submit" data-wait="Please wait..." class="submit-button-2 w-button" value="Next -&gt;">
                    </form>
                    <div class="w-form-done">
                        <div>Thank you! Your submission has been received!</div>
                    </div>
                    <div class="w-form-fail">
                        <div>Oops! Something went wrong while submitting the form.</div>
                    </div>
                </div>
                <div class="back-link-div">
                    <a href="#" class="link-block-4 w-inline-block" @click.prevent="questionId--">
                        <div class="text-block-146">&lt; Go Back</div>
                    </a>
                </div>
            </div>
            <div class="slide3" v-bind:class="{ 'hide-element': questionId !== 12 }">
                <h1 class="heading-39 medication">Do you currently take any GLP Weight Loss Medications?</h1>
                <div class="form-block-3 w-form">
                    <form id="email-form" name="email-form" data-name="Email Form" method="get" class="form-3" data-wf-page-id="668d55c8f0b55e9fc41fd2be" data-wf-element-id="f8850746-b7ef-edba-42d4-5094f7ee5e0f">
                        <div class="div-block-965">
                            <a href="#" @click.prevent="setCurrentTaking('Yes')" class="button-55 w-button">YES</a>
                            <a href="#" @click.prevent="setCurrentTaking('No')" class="button-55 w-button">NO</a>
                        </div>
                    </form>
                    <div class="w-form-done">
                        <div>Thank you! Your submission has been received!</div>
                    </div>
                    <div class="w-form-fail">
                        <div>Oops! Something went wrong while submitting the form.</div>
                    </div>
                </div>
                <div class="back-link-div">
                    <a href="#" class="link-block-4 w-inline-block" @click.prevent="questionId--">
                        <div class="text-block-146">&lt; Go Back</div>
                    </a>
                </div>
            </div>
            <div class="slide3" v-bind:class="{ 'hide-element': questionId !== 13 }">
                <div class="div-block-1287">
                    <div class="text-block-345">Ok </div><img sizes="(max-width: 479px) 12px, 20px" srcset="@/assets/images/Thumbs-Up-Emoji-Free-Download-IOS-Emojis-p-500.png 500w, @/assets/images/Thumbs-Up-Emoji-Free-Download-IOS-Emojis.png 600w" alt="" src="@/assets/images/Thumbs-Up-Emoji-Free-Download-IOS-Emojis.png" loading="lazy" class="image-245">
                    <div class="text-block-345">We can help you save and lose weight!</div>
                </div>
                <h1 class="heading-39 meds">What medication do you currently take?</h1>
                <div class="form-block-3 w-form">
                    <form id="email-form" name="email-form" data-name="Email Form" method="get" class="form-3" data-wf-page-id="668d55c8f0b55e9fc41fd2be" data-wf-element-id="f8850746-b7ef-edba-42d4-5094f7ee5e29">
                        <div class="div-block-965">
                            <a href="#" class="button-55 _4choice w-button" @click.prevent="setCurrent('Ozempic')">Ozempic</a>
                            <a href="#" class="button-55 _4choice w-button" @click.prevent="setCurrent('Mounjaro')">Mounjaro</a>
                        </div>
                        <div class="div-block-965">
                            <a href="#" class="button-55 _4choice w-button" @click.prevent="setCurrent('Wegovy')">Wegovy</a>
                            <a href="#" class="button-55 _4choice w-button" @click.prevent="setCurrent('Zepbound')">Zepbound</a>
                        </div>
                        <div class="div-block-965">
                            <a href="#" class="button-55 w-button" @click.prevent="setCurrent('Semaglutide')">Semaglutide
                                Compound</a>
                            <a href="#" class="button-55 w-button" @click.prevent="setCurrent('Tirzepatide')">Tirzepatide
                                Compound</a>
                        </div>
                    </form>
                    <div class="w-form-done">
                        <div>Thank you! Your submission has been received!</div>
                    </div>
                    <div class="w-form-fail">
                        <div>Oops! Something went wrong while submitting the form.</div>
                    </div>
                </div>
                <div class="back-link-div">
                    <a href="#" class="link-block-4 w-inline-block" @click.prevent="questionId--">
                        <div class="text-block-146">&lt; Go Back</div>
                    </a>
                </div>
            </div>

            <div class="slide11" v-bind:class="{ 'hide-element': questionId !== 14 }">
                <h1 class="heading-39 state">What is your current doses?<br></h1>
                <p class="paragraph-79">We need to match you to a licensed clinician in your state.</p>
                <div class="form-block-3 b w-form">
                    <form id="email-form" @submit.prevent="questionId++" class="form-3 b">
                        <div class="div-block-965 b">
                            <select v-model="people.phase_current_med" required class="select-field-3 form w-select">
                                <option value="" disabled selected>Select Dose</option>
                                <option v-for="dose in doses" :value="dose.phase" :key="dose.phase">{{ dose.dose }} mg</option>
                            </select>
                        </div>
                        <input type="submit" data-wait="Please wait..." class="submit-button-2 b email w-button" value="Next -&gt;">
                    </form>
                    <div class="w-form-done">
                        <div>Thank you! Your submission has been received!</div>
                    </div>
                    <div class="w-form-fail">
                        <div>Oops! Something went wrong while submitting the form.</div>
                    </div>
                </div>
                <div class="back-link-div">
                    <a href="#" class="link-block-4 w-inline-block" @click.prevent="questionId--">
                        <div class="text-block-146">&lt; Go Back</div>
                    </a>
                </div>
            </div>
            <div class="slide11" v-bind:class="{ 'hide-element': questionId !== 15 }">
                <h1 class="heading-39 state">What state do you reside in?<br></h1>
                <p class="paragraph-79">We need to match you to a licensed clinician in your state.</p>
                <div class="form-block-3 b w-form">
                    <form id="email-form" @submit.prevent="checkState()" class="form-3 b">
                        <div class="div-block-965 b">
                            <select v-model="people.state" required class="select-field-3 form w-select">
                                <option value="" disabled selected>State...</option>
                                <option v-for="(value, key) in allstate" :value="value" :key="value">{{ key }} </option>
                            </select>
                        </div>
                        <input type="submit" data-wait="Please wait..." class="submit-button-2 b email w-button" value="Next -&gt;">
                    </form>
                    <div class="w-form-done">
                        <div>Thank you! Your submission has been received!</div>
                    </div>
                    <div class="w-form-fail">
                        <div>Oops! Something went wrong while submitting the form.</div>
                    </div>
                </div>
                <div class="back-link-div">
                    <a href="#" class="link-block-4 w-inline-block" @click.prevent="checkCurrentDosesOnBack()">
                        <div class="text-block-146">&lt; Go Back</div>
                    </a>
                </div>
            </div>
            <div class="slide12" v-bind:class="{ 'hide-element': questionId !== 16 }">
                <h1 class="heading-39 number"><strong>What is your mobile phone number?</strong></h1>
                <p class="paragraph-79">Enter the best phone to reach you on just in case your doctor has any questions
                    regarding your medical information.</p>
                <div class="phone-checks desk">
                    <div class="div-block-1362"><img src="@/assets/images/icon_check-copy-3.svg" loading="lazy" alt="" class="image-275">
                        <div class="text-block-423">Updates from your doctor</div>
                    </div>
                    <div class="div-block-1362"><img src="@/assets/images/icon_check-copy-3.svg" loading="lazy" alt="" class="image-275">
                        <div class="text-block-423">Shipping notifications</div>
                    </div>
                    <div class="div-block-1362"><img src="@/assets/images/icon_check-copy-3.svg" loading="lazy" alt="" class="image-275">
                        <div class="text-block-423">Prescription alerts</div>
                    </div>
                    <div class="div-block-1362"><img src="@/assets/images/icon_check-copy-3.svg" loading="lazy" alt="" class="image-275">
                        <div class="text-block-423">Latest offers from Yara</div>
                    </div>
                </div>
                <div class="form-block-3 b w-form">
                    <form id="email-form" @submit.prevent="questionId++" class="form-3 b">
                        <div class="div-block-965 b">
                            <div class="phone-entry-div"><input class="phone-number-field _1 form w-input" v-model="people.phone_part.part1" pattern="[0-9]*" maxlength="3" minlength="3" @keyup="focusNext" placeholder="(000)" type="text" required>
                                -<input class="phone-number-field form w-input" v-model="people.phone_part.part2" pattern="[0-9]*" @keyup="focusNext" maxlength="3" minlength="3" placeholder="000" type="text" required>
                                -<input class="phone-number-field _2 form w-input" v-model="people.phone_part.part3" pattern="[0-9]*" maxlength="4" minlength="4" placeholder="0000" type="text" required>
                            </div>
                        </div><input type="submit" data-wait="Please wait..." class="submit-button-2 b phone w-button" value="Next -&gt;">
                        <div class="phone-checks mobile">
                            <div class="div-block-1361">
                                <div class="div-block-1362"><img src="@/assets/images/icon_check-copy-3.svg" loading="lazy" alt="" class="image-275">
                                    <div class="text-block-423">Doctor Updates</div>
                                </div>
                                <div class="div-block-1362"><img src="@/assets/images/icon_check-copy-3.svg" loading="lazy" alt="" class="image-275">
                                    <div class="text-block-423">Concierge Access</div>
                                </div>
                            </div>
                            <div class="div-block-1361 _2">
                                <div class="div-block-1362"><img src="@/assets/images/icon_check-copy-3.svg" loading="lazy" alt="" class="image-275">
                                    <div class="text-block-423">Shipping Info</div>
                                </div>
                                <div class="div-block-1362"><img src="@/assets/images/icon_check-copy-3.svg" loading="lazy" alt="" class="image-275">
                                    <div class="text-block-423">Latest Offers</div>
                                </div>
                            </div>
                        </div>
                        <p class="paragraph-78 centered">By clicking &quot;Next,&quot; you agree to receive communications from
                            Yara, including AI-generated calls, automated dialing systems, text messages, emails, and other
                            communication methods, to the contact information provided. These communications may include promotions,
                            updates, and follow-up information related to your inquiry or our services. Standard message and data
                            rates may apply. You can opt-out at any time. For more details, please review our <a href="https://joinyara.com/privacy-policy-new" target="_blank">Privacy Policy</a>.</p>
                    </form>
                </div>
                <div class="back-link-div">
                    <a href="#" class="link-block-4 w-inline-block" @click.prevent="questionId--">
                        <div class="text-block-146">&lt; Go Back</div>
                    </a>
                </div>
            </div>
            <div class="slide11" v-bind:class="{ 'hide-element': questionId !== 17 }">
                <h1 class="heading-39">Please enter your email</h1>
                <p class="paragraph-79">We will send you confirmation of approval &amp; discount offers.</p>
                <div class="form-block-3 b w-form">
                    <form @submit.prevent="setValueMode()" class="form-3 b">
                        <div class="div-block-965 b">
                            <input class="text-field-33 email form w-input" maxlength="256" name="Email" data-name="Email" placeholder="Enter your email" type="email" v-model="people.email" id="Email" required>
                        </div><input type="submit" data-wait="Please wait..." class="submit-button-2 b email w-button" value="Next -&gt;">
                        <p class="paragraph-78">By clicking &quot;Next,&quot; you agree to receive communications from Yara,
                            including AI-generated calls, automated dialing systems, text messages, emails, and other communication
                            methods, to the contact information provided. These communications may include promotions, updates, and
                            follow-up information related to your inquiry or our services. Standard message and data rates may
                            apply. You can opt-out at any time. For more details, please review our <a href="https://joinyara.com/privacy-policy-new" target="_blank">Privacy Policy</a>.</p>
                    </form>
                    <div class="w-form-fail" v-if="emailError" style="display:block;">
                        <div>Please enter valid email.</div>
                    </div>
                </div>
                <div class="back-link-div">
                    <a href="#" class="link-block-4 w-inline-block" @click.prevent="questionId--">
                        <div class="text-block-146">&lt; Go Back</div>
                    </a>
                </div>
            </div>
            <!--<div class="div-block-117">
        <div class="question-container-3 w-container">
          <h1 class="heading-19 form-1">Which program are you signing up for?<br></h1>
        </div>
        <div class="container-10 w-container">
          <div class="w-form">
            <form id="email-form" name="email-form" data-name="Email Form" method="get" data-wf-page-id="668d55c8f0b55e9fc41fd2be" data-wf-element-id="f8850746-b7ef-edba-42d4-5094f7ee5e75">
              <div class="div-block-291 product">
                <div class="div-block-237 products">
                  <a href="#" class="link-block-11 tirz w-inline-block">
                    <div class="div-block-1091 tirz">
                      <div class="text-block-211"><span class="text-span-27 tirz">Most Effective</span></div><img loading="lazy" src="https://uploads-ssl.webflow.com/657b2233d6a286f5c9f3844c/65a8b55c0b759f07aa5e3058_yara%20placeholder.svg" alt="" class="image-180">
                      <div class="text-block-212"><span class="text-span-clinically">Trizepatide</span></div>
                      <div class="text-block-213">COMPOUND</div>
                      <p class="paragraph-60">Same active ingredient as <strong>Mounjaro® &amp; Zepbound®</strong> for a fraction of the cost.</p>
                      <div class="div-block-1092"><img loading="lazy" src="@/assets/images/icon_Check.svg" alt="" class="image-181">
                        <div class="text-block-214">A once-weekly injection.</div>
                      </div>
                      <div class="div-block-1092"><img loading="lazy" src="@/assets/images/icon_Check.svg" alt="" class="image-181">
                        <div class="text-block-214">Most Effective GLP-1</div>
                      </div>
                      <div class="div-block-1092"><img loading="lazy" src="@/assets/images/icon_Check.svg" alt="" class="image-181">
                        <div class="text-block-214">Produced in the USA.</div>
                      </div>
                    </div>
                    <div class="form-price-div">
                      <div class="form-price">
                        <div class="text-block-442">Starting at</div>
                        <div class="amount">
                          <div class="text-block-444">$</div>
                          <div class="text-block-443">449</div>
                        </div>
                        <div class="text-block-445">a month</div>
                      </div>
                    </div>
                  </a>
                  <a href="#" class="link-block-11 sema w-inline-block">
                    <div class="div-block-1091 sema">
                      <div class="text-block-211"><span class="text-span-27 sema">Most Popular</span></div><img loading="lazy" src="https://uploads-ssl.webflow.com/657b2233d6a286f5c9f3844c/65a8b55c0b759f07aa5e3058_yara%20placeholder.svg" alt="" class="image-180">
                      <div class="text-block-212"><span class="text-span-clinically">Semaglutide</span></div>
                      <div class="text-block-213">COMPOUND</div>
                      <p class="paragraph-60">Same active ingredient as <strong>Ozempic® &amp; Wegovy®</strong> for a fraction of the cost.</p>
                      <div class="form-price-div">
                        <div class="form-price">
                          <div class="text-block-442">Starting at</div>
                          <div class="amount">
                            <div class="text-block-444">$</div>
                            <div class="text-block-443">299</div>
                          </div>
                          <div class="text-block-445">a month</div>
                        </div>
                      </div>
                      <div class="div-block-1092"><img loading="lazy" src="@/assets/images/icon_Check.svg" alt="" class="image-181">
                        <div class="text-block-214">A once-weekly injection.</div>
                      </div>
                      <div class="div-block-1092"><img loading="lazy" src="@/assets/images/icon_Check.svg" alt="" class="image-181">
                        <div class="text-block-214">Most Prescribed GLP-1 </div>
                      </div>
                      <div class="div-block-1092"><img loading="lazy" src="@/assets/images/icon_Check.svg" alt="" class="image-181">
                        <div class="text-block-214">Produced in the USA.</div>
                      </div>
                    </div>
                  </a>
                </div>
                <div class="div-block-1385">
                  <a href="#" class="link-30">Not sure which one?</a>
                </div>
                <a href="#" class="link-block-4 w-inline-block" @click.prevent="questionId--">
                  <div class="text-block-146">&lt; Go Back</div>
                </a>
                <div class="div_assistant">
                  <div class="div-block-286">
                    <div class="div-block-289"><img width="50" loading="lazy" alt="" src="@/assets/images/Girl_help.png"></div>
                    <div class="div-block-290">
                      <p class="paragraph-37">Get pre-qualified  minutes!</p>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <div class="w-form-done">
              <div>Thank you! Your submission has been received!</div>
            </div>
            <div class="w-form-fail">
              <div>Oops! Something went wrong while submitting the form.</div>
            </div>
          </div>
        </div>
      </div>-->

            <div class="slide-1" v-bind:class="{ 'hide-element': questionId !== 20 }">
                <div class="div-block-226"><img loading="lazy" src="@/assets/images/icon_sad-copy.svg" alt="" class="image-205">
                    <h1 class="heading-39 form">We&#x27;re Sorry</h1>
                    <p class="paragraph-67">Based on the information you provided, you currently do not qualify for our weight
                        loss programs.</p>
                    <div class="text-block-149">All we will need is some basic info before we schedule your teledoc appointment.
                    </div>

                </div>
            </div>
        </div>
    </div>
    <div class="included-space" :style="heightFooter"></div>
</div>
</template>

<script>
import loading from '../../common/loadingScreen.vue'
import state from "../../utils/state.json"

export default {
    name: 'HelloWorld',
    props: ['baseUrl', 'mainlogo', 'iconSVGColor'],
    components: {
        'loading': loading
    },
    data() {
        return {
            allstate: state,
            loading: false,
            headings:'Are you ready to qualify for a <span class="text-span-70">GLP weight loss</span> program in less than <span class="text-span-70">2 minutes?</span>',
            altpoints:false,
            questionId: 1,
            optionsWidth: `calc(50% - 10px)`,
            currentQuestionIndex: 0,
            emailError: false,
            dobError: '',
            weight_negatively_affecting_physical_mental_health: [],
            goals_are_you_looking_to_accomplish: [],
            medical_condition: [],
            tried_in_past: [],
            people: {
                first_name: '',
                last_name: '',
                phone: '',
                email: '',
                gender: 'Female',
                breastfeeding: '',
                state_residing:'',
                surgery: '',
                weight: '',
                height: {
                    ft: '',
                    inch: ''
                },
                dob: {
                    mm: '',
                    dd: '',
                    yyyy: ''
                },
                phone_part: {
                    part1: "",
                    part2: "",
                    part3: "",
                },
                state: '',
                currently_taking_glp: "No",
                weight_negatively_affecting_physical_mental_health: '',
                weight_loss_goal: '',
                goals_are_you_looking_to_accomplish: '',
                concerned_about_weight_for: [],
                desired_weight: '',
                name_current_med: "none",
                phase_current_med: "",
                ip_address: '',
                aff_id: '',
                tid: '',
                utm_source: '',
                utm_medium: '',
                utm_campaign: '',
                utm_content: '',
                utm_term: '',
                medical_condition: '',
                height_in_inch:'',
                dob_in_yyyy_mm_dd:'',
            },
            options_medical: [
                "Type 2 diabetes ON INSULIN",
                "Type 1 diabetes",
                "Diabetic retinopathy",
                "End-stage liver disease (cirrhosis)",
                "End-stage kidney disease (on or about to be on dialysis)",
                "Current or prior eating disorder (anorexia/bulimia)",
                "Current suicidal thoughts and/or prior suicidal attempt",
                "History of organ transplant on anti-rejection medication",
                "Severe gastrointestinal condition (gastroparesis, blockage, inflammatory bowel disease)",
                "Personal or family history of thyroid cyst/nodule, thyroid cancer, medullary thyroid carcinoma, or multiple endocrine neoplasia syndrome type 2",
                "History of or current pancreatitis",
                "None of the above"
            ],
            selectedOptions: [],
            doses: [{
                    "phase": 1,
                    "dose": 2.5
                },
                {
                    "phase": 2,
                    "dose": 5
                },
                {
                    "phase": 3,
                    "dose": 7.5
                },
                {
                    "phase": 4,
                    "dose": 10
                },
                {
                    "phase": 5,
                    "dose": 12.5
                },
                {
                    "phase": 6,
                    "dose": 15
                }
            ]
        }
    },
    computed: {
        heightFooter() {
            return 'height: 280px;';
        },
    },
    watch: {
        questionId(newVal) {
            this.scrolltoTop();
            if (newVal >= 1) {
                this.focusOnFirstVisibleInput();
                this.checkQuestionId();
            }
        }
    },
    mounted() {
        this.checkUrlParameters();
        this.setHeadings();
    },
    methods: {
        checkState(){
            if(["MS","LA"].includes(this.people.state)){
                this.questionId=20;
            }else{
                this.questionId ++;
            }
        },
      setHeadings(){
            if(this.$route.name==='DirectToAppointment'){
                this.altpoints = true;
                this.headings = 'Please fill out this quick survey <span class="text-span-70">to schedule your free Medical Weight loss consultation</span> to make sure you are eligible.';
            }
        },
        setAttributeValue(field, val) {
            this.people[field] = val;
            this.questionId++;
        },
        getIconPath(path) {
            const images = require.context('@/assets/images', false, /\.svg$/);
            return images(`./${path}${this.iconSVGColor}.svg`);
        },
        checkCurrentDosesOnBack() {
            if (this.people.currently_taking_glp == "No") {
                this.questionId -= 3;
            } else {
                this.questionId--;
            }
        },
        setCurrentTaking(val) {
            this.people.currently_taking_glp = val;
            if (val == 'Yes') {
                this.questionId++;
            } else {
                this.questionId += 3;
            }
        },
        setCurrent(val) {
            this.people.name_current_med = val;
            if (val == 'Semaglutide' || val == 'Ozempic' || val == 'Wegovy') {
                this.doses = [{
                    "phase": 1,
                    "dose": 0.27
                }, {
                    "phase": 2,
                    "dose": 0.54
                }, {
                    "phase": 3,
                    "dose": 1.08
                }, {
                    "phase": 4,
                    "dose": 2.16
                }, {
                    "phase": 5,
                    "dose": 2.5
                }];
            }
            this.questionId++;
        },
        checkUrlParameters() {
            const url = new URL(window.location.href);
            const urlParams = url.searchParams;

            const getParamOrCookie = (paramName) => 
                urlParams.get(paramName) || 
                document.cookie.split('; ').find(row => row.startsWith(`${paramName}=`))?.split('=')[1] || 
                null;

            // Set all URL parameters to this.people
            urlParams.forEach((value, key) => this.people[key] = value);

            // Special handling for phone number
            if (this.people.phone) {
                const phone = this.people.phone.toString();
                this.people.phone_part = {
                    part1: phone.slice(0, 3),
                    part2: phone.slice(3, 6),
                    part3: phone.slice(6)
                };
            }

            // Handle affiliate parameters
            ['utm_campaign', 'tid', 'utm_source'].forEach(param => {
                const value = getParamOrCookie(param);
                if (value) {
                    this.people[param] = value;
                    if (param === 'utm_campaign') this.people.aff_id = value;
                }
            });
        },
        scrolltoTop() {
            setTimeout(function () {
                window.scroll({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                });
            }, 100);
        },
        focusOnFirstVisibleInput() {
            try {

                const targetDiv = document.getElementById(this.questionId);
                const firstInput = targetDiv.querySelector('input');
                if (firstInput) {
                    setTimeout(function () {
                        firstInput.focus();
                    }, 10);
                }
            } catch (e) {
                console.log(e);
            }
        },
        calculateBMI() {
            var heightInInches = parseInt(this.people.height.ft * 12) + parseInt(this.people.height.inch);
            this.people.height_in_inch = heightInInches;
            let cal = (this.people.weight * 703);
            let heigh = (heightInInches * heightInInches);
            var bmi = cal / heigh;
            if (bmi < 27) {
                this.questionId = 20;
            } else {
                this.questionId++;
            }
        },
        isValidDate(dt) {
            const [m, d, y] = dt.split('-').map(Number);
            const maxDays = [31, ((y % 4 === 0 && y % 100 !== 0) || y % 400 === 0) ? 29 : 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
            return y >= 1925 && y <= 2024 && m > 0 && m <= 12 && d > 0 && d <= maxDays[m - 1];
        },
        ValidateDOB() {
            const { mm, dd, yyyy } = this.people.dob;
            this.people.dob_in_yyyy_mm_dd = `${yyyy}-${mm}-${dd}`
            if (this.isValidDate(`${mm}-${dd}-${yyyy}`)) {
                const age = new Date().getFullYear() - yyyy - (new Date() < new Date(yyyy, mm - 1, dd));
                this.questionId = age < 18 || age > 75 ? 20 : this.questionId + 1;
            } else {
                this.dobError = true;
            }
        },
        setValueForSurgery(val) {
            let vm = this;
            vm.people.surgery = val;
            setTimeout(function () {
                if (vm.people.surgery === "Yes") {
                    vm.questionId = 20;
                } else {
                    vm.questionId++;
                }
            }, 100);
        },
        setValueForBreastFeed(val) {
            let vm = this;
            vm.people.breastfeeding = val;
            setTimeout(function () {
                if (vm.people.breastfeeding === "Yes") {
                    vm.questionId = 20;
                } else {
                    vm.questionId++;
                }
            }, 10);
        },
        setValueForGender(val) {
            let vm = this;
            vm.people.gender = val;
            setTimeout(function () {
                vm.questionId++;
            }, 200);

        },
        checkGender() {
            if (this.people.gender === "Male") {
                this.questionId -= 2;
            } else {
                this.questionId--;
            }
        },
        isSelected(option) {
            return this.selectedOptions.includes(option);
        },
        toggleOption(option) {
            if (option === "None of the above") {
                this.selectedOptions = [option];
            } else {
                const index = this.selectedOptions.indexOf(option);
                if (index !== -1) {
                    this.selectedOptions.splice(index, 1);
                } else {
                    this.selectedOptions.push(option);
                }
                if (this.selectedOptions.includes("None of the above")) {
                    const index = this.selectedOptions.indexOf("None of the above");
                    this.selectedOptions.splice(index, 1);
                }
            }
        },
        focusNext(event) {
            const currentInput = event.target;
            const nextInput = currentInput.nextElementSibling;
            const prevInput = currentInput.previousElementSibling;
            if (event.key === "Backspace" && currentInput.value === "") {
                if (prevInput) {
                    prevInput.focus();
                }
            } else if (currentInput.value.length === currentInput.maxLength && nextInput) {
                nextInput.focus();
            }
        },
        checkMedicalCondition() {
            if(this.medical_condition.length>0){
                this.questionId = 20;
            }else{
                this.questionId ++;
            }
            
        },
        validateEmail(email) {
            const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            return regex.test(email);
        },
        setValueMode() {
            this.emailError = false;
            this.people.phone = this.people.phone_part.part1 + this.people.phone_part.part2 + this.people.phone_part.part3;
            this.people.goals_are_you_looking_to_accomplish = this.goals_are_you_looking_to_accomplish.join('\n');
            this.people.tried_in_past = this.tried_in_past.join('\n');
            this.people.discount = this.$route.query.discount;
            let k = this.validateEmail(this.people.email);
            try {
                this.people.userAgent = navigator.userAgent;
            } catch (error) {
                console.log(error);
            }
            this.people.brand = window.location.hostname == "ifg.joinyara.com" ? "It Feels Good" : "Yara";
            this.people.user_type = (this.$route.query.nextpage || "regular") == "specialoffer" ? "discounted" : "regular";
            this.people.frm_ref = this.$route.name;
            this.people.state_residing = this.people.state;
            try{
                this.people.ip_address = JSON.parse(localStorage.getItem("ip_address"));
            }catch(error){
                console.log(error)
            }
            
            if (k) {
                this.submitFormAndMoveToCheckout();
            } else {
                this.emailError = true;
            }
        },
        submitFormAndMoveToCheckout() {
            this.loading = true;
            localStorage.setItem("formResponse", JSON.stringify(this.people));
            window.axios.post(`${this.baseUrl}/intake-form`, this.people, {
                headers: {
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*"
                }
            }).then((response) => {
                if (response.data.status == 'success') {
                    this.people.health_id = response.data.health_id;
                    this.people.id = response.data.id;
                    localStorage.setItem("formResponse", JSON.stringify(this.people));
                    if(this.$route.name=="DirectToAppointment"){
                      this.$router.push({
                        name: 'HelpCalendar',
                        query: {
                                "health_id": response.data.health_id,
                                "id": response.data.id,
                                "first_name": this.people.first_name,
                                "last_name": this.people.last_name,
                                "phone": this.people.phone,
                                "email": this.people.email,
                                "phase": this.people.phase_current_med,
                                "plan": this.people.name_current_med,
                                "state": this.people.state_residing,
                                "frm_ref": this.$route.name
                            }
                        });
                    }else if (this.$route.query.offer == "ninetynine_direct") {
                        this.$router.push({
                            name: 'CheckoutOffer',
                            query: {
                                "health_id": response.data.health_id,
                                "id": response.data.id,
                                "name": this.people.first_name,
                                "state": this.people.state,
                                "frm_ref": this.$route.name,
                                "weight": this.people.weight,
                                "goal": this.people.desired_weight,
                                "offer": this.$route.query.offer,
                                "aegate" : "Mjk5",
                                "phase": 1,
                                "plan": "Semaglutide",
                                "pay_terms": 1
                            }
                        });
                    }else if(this.$route.query.nextpage=="start_now"){
                        this.$router.push({
                        name: 'CheckoutStep2',
                        query: {
                                "health_id": response.data.health_id,
                                "id": response.data.id,
                                "name": this.people.first_name,
                                "phase": 1,
                                "plan": "Semaglutide",
                                "pay_terms" : 1,
                                "state": this.people.state,
                                "frm_ref": this.$route.name,
                                "aegate": "Mjk5",
                                "showheader" : true
                            }
                        }); 
                    }else if(this.$route.query.nextpage=="specialoffer"){
                        this.$router.push({
                        name: 'SpecialOffer',
                        query: {
                                "health_id": response.data.health_id,
                                "id": response.data.id,
                                "name": this.people.first_name,
                                "phase": 1,
                                "plan": "Semaglutide",
                                "pay_terms" : 1,
                                "state": this.people.state,
                                "frm_ref": this.$route.name,
                                "showheader" : true
                            }
                        }); 
                    }else if(this.$route.query.discount){
                    this.$router.push({
                        name: 'ReportPageDiscount',
                        query: {
                                "health_id": response.data.health_id,
                                "id": response.data.id,
                                "name": this.people.first_name,
                                "phase": this.people.phase_current_med,
                                "plan": this.people.name_current_med,
                                "state": this.people.state,
                                "frm_ref": this.$route.name,
                                "weight": this.people.weight,
                                "goal": this.people.desired_weight,
                                "discount": this.$route.query.discount,
                            }
                        });
                   }else{
                    this.$router.push({
                        name: 'ReportPage',
                        query: {
                                "health_id": response.data.health_id,
                                "id": response.data.id,
                                "name": this.people.first_name,
                                "phase": this.people.phase_current_med,
                                "plan": this.people.name_current_med,
                                "state": this.people.state,
                                "frm_ref": this.$route.name,
                                "weight": this.people.weight,
                                "goal": this.people.desired_weight
                            }
                        });
                   }
                   try{
                        window.fbq('track', 'AddToCart');
                    }catch(error){
                        console.log(error)
                    }
                }
                this.loading = false;
            }).catch((error) => {
                console.log(error)
                alert("An Internal error occurred")
            });
        },
        numberAnimate() {
            let elt = this.$refs.numberAnimate;
            let endNbr = parseInt((((this.questionId - 1) / 11) * 100).toFixed(0));
            this.incNbrRec(0, endNbr, elt);

        },
        incNbrRec(i, endNbr, elt) {
            let vm = this;
            if (i <= endNbr) {
                elt.innerHTML = i + "%";
                setTimeout(function () {
                    vm.incNbrRec(i + 1, endNbr, elt);
                }, 15);
            }
        },
        checkQuestionId() {
            if (this.questionId == 20) {
                window.axios.post(`${this.baseUrl}/mark-rejected`, this.people);
            }
        },
    },
    created(){
        this.scrolltoTop();
    }

}
</script>

<style scoped>
.hide-element{
    display: none;
}
.text-danger {
    color: red !important;
    border: solid 1px red !important;
}
</style>
